import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

export default function AlertConfirmation({
  open,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  confirmAction,
  cancelAction,
  platformStyles,
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={cancelAction}
        style={platformStyles}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="inline-block px-6 py-8 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ">
                <div className="flex-col text-center sm:flex sm:items-center">
                  <div className="flex items-center justify-center w-12 h-12 mx-auto text-red-300 rounded-full">
                    <ExclamationCircleIcon
                      className="!stroke-1 text-platform h-11 w-11"
                      aria-hidden="true"
                    />{" "}
                  </div>
                  <div className="mt-3 text-center sm:text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                  </div>
                </div>
                <div className="justify-center gap-5 mt-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="px-6 py-3 text-white rounded-md bg-platform"
                    onClick={confirmAction}
                  >
                    {confirmButtonText}
                  </button>
                  <button
                    type="button"
                    className="px-6 py-3 border-2 rounded-md lg:px-5 lg:py-2 text-platform border-platform"
                    onClick={cancelAction}
                    ref={cancelButtonRef}
                  >
                    {cancelButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
