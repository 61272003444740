/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState, useEffect } from "react";
import { useLottie } from "lottie-react";
import depositAnimation from "../../assets/deposit-animation.json";
import { Dialog, Transition } from "@headlessui/react";

import { XIcon } from "@heroicons/react/outline";
import { ChevronRightIcon } from "@heroicons/react/solid";

import NumberField from "../fields/NumberField";
import NumberDisplay from "../fields/NumberDisplay";

export default function CreateDeposit({
  open,
  setClose,
  invoiceDate,
  invoiceTotal,
  invoiceCurrency,
  invoiceKey,
  platformStyles,
  createDeposit,
  isTrial,
}) {
  const [depositValue, setDepositValue] = useState("");
  const [depositTotal, setDepositTotal] = useState("");
  const [balanceTotal, setBalanceTotal] = useState(invoiceTotal);
  const [depositDate, setDepositDate] = useState(invoiceDate);
  const [balanceDate, setBalanceDate] = useState(false);

  useEffect(() => {
    setBalanceTotal(invoiceTotal);
    setDepositDate(invoiceDate);
  }, [invoiceDate, invoiceTotal]);

  const depositValueChange = (value) => {
    setDepositValue(value);
    let depositTotal = 0;
    if (/%$/.test(value)) {
      let calculatedDepositTotal = value.replace(/[^0-9.]/g, "");
      calculatedDepositTotal = Number(calculatedDepositTotal);
      calculatedDepositTotal = (calculatedDepositTotal / 100) * invoiceTotal;
      calculatedDepositTotal = calculatedDepositTotal.toFixed(2);
      depositTotal = calculatedDepositTotal;
    } else {
      value = value.replace(/[^0-9.]/g, "");
      depositTotal = Number(value);
    }
    setBalanceTotal(invoiceTotal - depositTotal);
    setDepositTotal(depositTotal);
  };

  const handleCreateDeposit = (
    invoiceKey,
    depositTotal,
    depositDate,
    balanceDate,
  ) => {
    setDepositValue("");
    setDepositTotal("");
    setDepositDate("");
    setBalanceDate("");
    setBalanceTotal("");
    createDeposit(invoiceKey, depositTotal, depositDate, balanceDate);
    setClose(false);
  };

  const DepositAnimation = () => {
    const { View } = useLottie(
      {
        animationData: depositAnimation,
        loop: true,
        autoplay: true,
      },
      {
        height: 400,
        width: 400,
      },
    );
    return View;
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={setClose}
        style={platformStyles}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-white bg-opacity-90"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-flex flex-row overflow-hidden text-left align-bottom transition-all transform bg-white border border-gray-100 shadow-xl shadow-neutral-100 rounded-2xl sm:max-w-4xl sm:my-8 sm:align-middle sm:w-full">
              <div className="absolute top-0 right-0 z-50 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none"
                  onClick={() => setClose(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex-1 px-10 py-8">
                <div className="flex flex-col gap-5 pt-5">
                  <h1 className="flex items-center text-2xl font-semibold leading-6 text-gray-900">
                    Create Deposit Invoice{" "}
                    {isTrial && (
                      <span className="relative px-3 py-1 text-xs font-semibold tracking-wide uppercase rounded-full -right-4 bg-palePrimary text-secondary">
                        Pro
                      </span>
                    )}
                  </h1>
                  <div>
                    <p className="text-sm leading-tight ">
                      Split this invoice into a deposit invoice with a fixed
                      amount, and a balance invoice with the line items plus
                      deposit deducted.{" "}
                      <a
                        href={process.env.REACT_APP_DEPOSITS_LINK}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center text-platform"
                      >
                        Learn more{" "}
                        <ChevronRightIcon className="inline-block w-4 h-4 text-platform" />
                      </a>
                    </p>
                    <p className="mt-2 text-xs text-grey">
                      Looking to include the same line items on all invoices?
                      <a
                        href={process.env.REACT_APP_SCHEDULES_LINK}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center text-platform"
                      >
                        Check out Payment Schedules instead{" "}
                        <ChevronRightIcon className="inline-block w-4 h-4 text-platform" />
                      </a>
                    </p>
                  </div>
                  <div className="flex flex-col gap-8 mt-5 text-left ">
                    <div className="flex flex-row gap-8 text-left ">
                      <div className="relative flex-1">
                        <label className="block text-xs text-grey">
                          Deposit amount
                        </label>
                        <NumberField
                          value={depositValue}
                          onChange={(value) => depositValueChange(value)}
                          currency={invoiceCurrency}
                          allowPercentage={true}
                          placeholder="e.g. $1000 or 20%"
                          className="flex h-8 "
                        />
                        {/%$/.test(depositValue) && (
                          <NumberDisplay
                            value={depositTotal}
                            currency={invoiceCurrency}
                            className="absolute right-0 mt-1 text-xs text-grey"
                          />
                        )}
                      </div>
                      <div className="flex-1">
                        <label className="block text-xs text-grey">
                          Deposit Invoice Date
                        </label>
                        <input
                          title="Add Date"
                          type="date"
                          className="w-full h-8 p-1 text-sm border-b bg-inherit border-inherit focus:outline-none focus:border-platform"
                          placeholder="YYYY-MM-DD"
                          value={depositDate}
                          onChange={(e) => setDepositDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row gap-8 text-left">
                      <div className="flex-1">
                        <label className="block text-xs text-grey">
                          Balance
                        </label>
                        <NumberDisplay
                          value={balanceTotal}
                          currency={invoiceCurrency}
                          className="flex items-center justify-end h-8 text-sm"
                        />
                      </div>
                      <div className="flex-1">
                        <label className="block text-xs text-grey">
                          Balance Invoice Date
                        </label>
                        <input
                          title="Add Date"
                          type="date"
                          className="w-full h-8 p-1 text-sm border-b bg-inherit border-inherit focus:outline-none focus:border-platform"
                          placeholder="YYYY-MM-DD"
                          value={balanceDate}
                          onChange={(e) => setBalanceDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full text-center">
                    <button
                      type="button"
                      className="px-6 py-3 text-white transition-all rounded-md bg-platform hover:opacity-90"
                      onClick={() =>
                        handleCreateDeposit(
                          invoiceKey,
                          depositTotal,
                          depositDate,
                          balanceDate,
                        )
                      }
                    >
                      Create Deposit
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center flex-1 blend-background">
                <DepositAnimation />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
