import React from "react";

export default function NumberPicker({
  value,
  className,
  updateHandler,
  handleKeypress,
}) {
  return (
    <label className={className}>
      <div className="flex items-center justify-center h-full">
        <div className="flex-1">
          <div
            className="flex items-center justify-center w-10 h-10 text-xl text-gray-300 border-2 border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:border-platform hover:text-platform"
            onClick={(e) => {
              e.preventDefault();
              updateHandler(value - 1);
            }}
          >
            -
          </div>
        </div>
        <input
          className="flex-shrink text-center border-0 text-9xl focus:outline-none"
          onFocus={() => {
            if (value === 1) {
              updateHandler("");
            }
          }}
          onBlur={() => {
            if (value === "") {
              updateHandler(1);
            }
          }}
          onChange={(e) => {
            e.preventDefault();
            updateHandler(e.target.value);
          }}
          onKeyPress={(e) => {
            handleKeypress(e);
          }}
          value={value}
          type="text"
          size="2"
        />
        <div className="flex-1">
          <div
            className="flex items-center justify-center w-10 h-10 text-xl text-gray-300 border-2 border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:border-platform hover:text-platform"
            onClick={(e) => {
              e.preventDefault();
              updateHandler(value + 1);
            }}
          >
            +
          </div>
        </div>
      </div>
    </label>
  );
}
