import React from "react";
import { DateTime, Interval } from "luxon";
import { waitUntil } from "async-wait-until";
import jwt_decode from "jwt-decode";

import InvoiceList from "./components/InvoiceList";
import ModeSelector from "./components/ModeSelector";
import Sidebar from "./components/Sidebar";

import ErrorBoundary from "./components/states/ErrorBoundary";
import ErrorState from "./components/states/ErrorState";
import LoadingState from "./components/states/LoadingState";

import InlineSuccess from "./components/modals/InlineSuccess";
import InlineLoading from "./components/modals/InlineLoading";
import AlertError from "./components/modals/AlertError";
import AlertConfirmation from "./components/modals/AlertConfirmation";

import "./assets/tailwind.css";
import Syncing from "./components/modals/Syncing";
/*

Notes on invoice identifiers

  key = internal ID just used inside react to distinguish components - saved in db for line items but not important
  id = database identifier for the invoice - important! Can only ever update the same deal you're authed on
  index = array index for updating purposes - could probably be deprecated in favour of key

*/
let api = process.env.REACT_APP_API_ROOT;

console.clear();

const accessToken = new URLSearchParams(document.location.search).get(
  "access_token",
);
console.log(accessToken);

// Container Component
window.id = 0;
class App extends React.Component {
  constructor(props) {
    // Pass props to parent class
    super(props);
    // Set initial state
    this.state = {
      invoices: [],
      invoiceErrors: [],
      deal: {
        total: "",
        settings: {
          reference: "",
          currency: "",
          company: "",
          tax: 0,
          taxType: "",
          mode: "",
          autofill: "",
        },
      },
      fetchedInvoices: false,
      loading: true,
      loadingInline: false,
      loadingTitle: "Loading...",
      error: false,
      errorAlert: false,
      successInline: false,
      successAlert: false,
      changed: false,
      confirmQBOStartingToday: false,
      showEditContact: false,
      hubspotAttachments: null,
      hubspotAttachmentsLoading: false,
      saveStatus: false,
      syncStatus: false,
      showUploadStatus: false,
    };
    this.updateTotals(false);
    this.generateRandomKey = this.generateRandomKey.bind(this);

    this.eventSource = null;
  }
  // Lifecycle method
  componentDidMount = async () => {
    try {
      const tokenData = await jwt_decode(accessToken);
      // Not secure, just getting a head start on loading connection data
      this.setState({
        connectionData: {
          connectionId: tokenData.connectionId,
          connectionType: tokenData.connectionType,
        },
      });
    } catch (error) {
      console.log(error);

      this.setError(
        error === "TokenExpiredError"
          ? "Session expired, please refresh page"
          : "Problem loading your data",
        false,
      );
    }
    await fetch(api + "/view/start", {
      headers: { "Content-Type": "application/json", accesstoken: accessToken },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error || Object.keys(res).length === 0) {
          throw res.error;
        }

        this.setState({
          hubspotData: res.hubspotData,
          connectionData: res.connectionData,
          preferences: res.preferences,
          allowAttachments: res.allowAttachments,
        });

        if (res.hubspotData?.custom_reference) {
          this.updateSetting(
            "custom_reference",
            res.hubspotData.custom_reference,
            true,
          );
        }
        if (res.hubspotData?.custom_notes) {
          this.updateSetting(
            "custom_notes",
            res.hubspotData.custom_notes,
            true,
          );
        }
        if (res.hubspotData?.custom_1) {
          this.updateSetting("custom_1", res.hubspotData.custom_1, true);
        }
        if (res.hubspotData?.custom_2) {
          this.updateSetting("custom_2", res.hubspotData.custom_2, true);
        }
        if (res.hubspotData?.custom_3) {
          this.updateSetting("custom_3", res.hubspotData.custom_3, true);
        }
        if (res.hubspotData?.custom_message) {
          this.updateSetting(
            "custom_message",
            res.hubspotData.custom_message,
            true,
          );
        }
        this.setState({ hubspot: res.deal });
      })
      .catch((error) => {
        !this.state.errorTitle &&
          this.setError(
            error === "TokenExpiredError"
              ? "Session expired, please refresh page"
              : "Problem loading your data",
            false,
          );
        console.error(error);
      });

    await fetch(api + "/invoices/get", {
      headers: { "Content-Type": "application/json", accesstoken: accessToken },
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.error) {
          throw res.error;
        }
        this.setState({ loadingTitle: "Creating your invoices.." });
        let invoices = this.processInvoices(res);
        let deal = this.processDeals(res.deal);
        this.setState({ deal: deal, fetchedInvoices: true });

        if (invoices.length) {
          this.setInvoiceState(invoices);
        } else if (
          this.state.preferences?.recurringBeta &&
          this.state.hubspotData.lineItemData.length
        ) {
          await waitUntil(() => this.state?.accounts, { timeout: 10000 });
          await this.autoFill();
        }

        if (deal.deal_id && this.state?.allowAttachments) {
          this.createEventSource(deal.deal_id);
        }

        this.setState({ loading: false });
      })
      .catch((error) => {
        !this.state.errorTitle &&
          this.setError(
            error === "TokenExpiredError"
              ? "Session expired, please refresh page"
              : "Problem loading your data",
            false,
          );
        console.error(error);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.successInline && this.state.successInline) {
      setTimeout(() => {
        this.setState({ successInline: false });
      }, 3000); // Close success messages after 3 seconds
    }
  }
  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  createEventSource(dealId) {
    this.eventSource = new EventSource(
      `${api}/sse?channel=${dealId}&access_token=${accessToken}`,
      { withCredentials: true },
    );
    this.eventSource.onmessage = this.eventSourceCallback;
  }

  eventSourceCallback = (event) => {
    const data = JSON.parse(event.data);
    const {
      event: eventName,
      attachmentId,
      attachmentValues,
      invoiceId,
    } = data;

    const invoiceIndex = this.state.invoices.findIndex(
      (invoice) => invoice.id == invoiceId,
    );

    if (invoiceIndex < 0) {
      return;
    }

    let attachments = this.state.invoices[invoiceIndex].attachments;

    if (eventName === "attachment-uploaded") {
      attachments = attachments.map((attachment) =>
        attachment.id == attachmentId
          ? { ...attachment, ...attachmentValues }
          : attachment,
      );
    }

    if (eventName === "attachment-deleted") {
      attachments = attachments.filter(
        (attachment) => attachment.id != attachmentId,
      );
    }

    if (eventName === "attachment-upload-failed") {
      attachments = attachments.map((attachment) =>
        attachment.id == attachmentId
          ? { ...attachment, status: "UPLOAD-FAILED" }
          : attachment,
      );
    }

    if (eventName === "attachment-delete-failed") {
      attachments = attachments.map((attachment) =>
        attachment.id == attachmentId
          ? { ...attachment, status: "DELETE-FAILED" }
          : attachment,
      );
    }

    this.updateInvoice(invoiceIndex, "attachments", attachments);
  };

  getAttachmentPropertyAttachments = async () => {
    const prop = this.state.preferences?.attachmentProperty;

    if (!prop || !this.state.hubspotData.properties[prop]) {
      return [];
    }

    const attachmentIds = this.state.hubspotData.properties[prop].split(";");

    const response = await fetch(
      `${api}/deal-attachments/get?attachmentIds=${attachmentIds.join(",")}`,
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      },
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error);
    }

    return json.attachments;
  };

  getHubspotAttachments = async () => {
    try {
      this.setState({ hubspotAttachmentsLoading: true });

      const noteIds = this.state.hubspotData.notes.map((n) => n.id);
      const emailIds = this.state.hubspotData.emails.map((n) => n.id);
      let attachmentIds = [];

      if (this.state.preferences?.attachmentProperty) {
        const prop = this.state.preferences.attachmentProperty;
        attachmentIds =
          this.state.hubspotData.properties[prop]?.split(";") || attachmentIds;
      }

      if (!noteIds.length && !attachmentIds.length && !emailIds.length) {
        this.setState({
          hubspotAttachments: [],
          hubspotAttachmentsLoading: false,
        });
        return;
      }

      const response = await fetch(
        `${api}/deal-attachments/get?noteIds=${noteIds.join(",")}&emailIds=${emailIds.join(",")}&attachmentIds=${attachmentIds.join(",")}`,
        {
          headers: {
            "Content-Type": "application/json",
            accesstoken: accessToken,
          },
        },
      );

      const json = await response.json();

      if (json.error) {
        throw new Error(json.error);
      }

      this.setState({
        hubspotAttachments: json.attachments,
        hubspotAttachmentsLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  processDeals(dealData) {
    let deal = {};
    if (typeof dealData !== "undefined") {
      deal = dealData;
      deal.total = this.state.hubspotData.properties.amount;
      deal.connectionType = this.state.connectionData.connectionType;
      deal.connectionId = this.state.connectionData.connectionId;
      deal.company_id = this.state.hubspotData.companyData.hs_object_id;
      console.log("process deal");
    } else {
      deal.total = this.state.hubspotData.properties.amount;
      deal.company_id = this.state.hubspotData.companyData.hs_object_id;
      deal.settings = this.state.deal.settings;
    }
    return deal;
  }

  doesInvoiceHaveCustomTaxes = (invoice, deal) => {
    const connectionType = this.state?.connectionData?.connectionType;
    const country = this.state?.connectionData?.country;
    const preferences = this.state.preferences;
    const isQboUs = connectionType === "qbo" && country === "US";

    if (preferences?.showCustomTaxes) {
      return preferences?.showCustomTaxes;
    } else if (isQboUs) {
      return invoice.taxType && invoice.taxType != deal.settings?.taxType;
    } else {
      return invoice.line_items.some((item) => {
        const taxTypesMatch = item.taxType == deal.settings?.taxType;
        const accountsMatch =
          item.account == deal.settings?.account ||
          (item.account === "NONE" && !deal.settings?.account);

        return !taxTypesMatch || (connectionType === "xero" && !accountsMatch);
      });
    }
  };

  processInvoices(invoiceData) {
    const connectionType = this.state?.connectionData?.connectionType;

    // helper function to convert json blob back to array of objects
    let invoices = invoiceData.invoices;
    const result = [];
    invoices.forEach((invoice) => {
      let lineResult = [];
      let lineItems = JSON.parse(invoice.line_items);

      for (var i in lineItems) {
        if (!Object.hasOwn(lineItems[i], "tracking")) {
          lineItems[i].tracking = {};
        }

        // The below code is required for compatibility with legacy data.
        if (connectionType === "xero") {
          if (!Object.hasOwn(lineItems[i], "tax")) {
            lineItems[i].tax = invoiceData.deal.settings?.tax || 0;
            lineItems[i].taxType = invoiceData.deal.settings?.taxType || false;
          }

          if (!Object.hasOwn(lineItems[i], "account")) {
            lineItems[i].account = invoiceData.deal.settings?.account || "NONE";
          }
        }

        lineResult.push(lineItems[i]);
      }
      invoice.line_items = lineResult;
      invoice.key = invoice.id.toString();
      invoice.customTaxes = this.doesInvoiceHaveCustomTaxes(
        invoice,
        invoiceData.deal,
      );

      result.push(invoice);
    });
    return result;
  }

  /* Converts line items from HubSpot into view versions */
  processLineItems(lineItems, preferences) {
    const connectionType = this.state?.connectionData?.connectionType;

    lineItems = lineItems.sort((a, b) =>
      Number(a.hs_position_on_quote) > Number(b.hs_position_on_quote) ? 1 : -1,
    );
    lineItems = lineItems
      .filter((item) => {
        // QBO supports whole invoice discount - so remove any lines with a negative price
        return connectionType === "qbo" && item.price < 0 ? false : true;
      })
      .map((item, index) => {
        // QBO does not support per-line discounts - so caculate this from HubSpot data first
        if (
          connectionType === "qbo" &&
          (item?.hs_discount_percentage || item?.discount)
        ) {
          item.price = item.amount / item.quantity;
          delete item.discount;
          delete item.hs_discount_percentage;
        }
        if (preferences?.line_item_append && item.price >= 0) {
          let tokens = {};
          //Get deal properties and save to token list
          const dealProperties = preferences.line_item_append?.deal_properties;
          if (dealProperties) {
            dealProperties.forEach((prop) => {
              tokens["#" + prop] =
                this.state.hubspotData.properties[prop] || "";
            });
          }
          //Get line item properties and save to token list
          const lineItemProperties =
            preferences.line_item_append?.line_item_properties;
          if (lineItemProperties) {
            lineItemProperties.forEach((prop) => {
              tokens["#" + prop] = item[prop] || "";
            });
          }
          // Generate a regex pattern that looks for all the tokens in the string
          // e.g. #hs_billing_start_date|'XYZ'  where 'XYZ' is the date format for luxon
          // https://moment.github.io/luxon/#/formatting?id=table-of-tokens
          const regexPattern = Object.keys(tokens)
            .map((token) => {
              // The pattern needs to capture an optional |'format' part
              return `${token}(\\|'([a-zA-Z\\s]+)')?`;
            })
            .join("|");

          // Create the appended string
          const appendString = preferences.line_item_append.string.replace(
            new RegExp(regexPattern, "gi"),
            function (matched) {
              console.log("matched", matched);
              // Use a local function to extract the token name and format
              const extractTokenAndFormat = (match) => {
                const matchParts = /(#\w+)(?:\|'([a-zA-Z\s]+)')?/.exec(match);
                return {
                  property: matchParts[1], // This is the token name
                  format: matchParts[2], // This is the format, which could be undefined if not present
                };
              };

              const { property, format } = extractTokenAndFormat(matched);
              let tokenValue = tokens[property];

              console.log("tokens", tokens);
              if (!tokenValue) {
                return "";
              }

              // parse the value from either ISO or a UNIX timestamp
              let date = DateTime.fromISO(tokenValue);
              if (date.isValid && date.year > 2020 && date.year < 2030) {
                tokenValue = date; // Use the DateTime object
              } else if (
                tokenValue !== "" &&
                tokenValue.toString().length === 13
              ) {
                // Attempt to parse as a UNIX timestamp
                date = DateTime.fromMillis(Number(tokenValue));
                if (date.isValid && date.year > 2020 && date.year < 2030) {
                  tokenValue = date; // Use the DateTime object
                }
              }

              if (format) {
                tokenValue = tokenValue.toFormat(format);
                console.log("process date");
              } else if (DateTime.isDateTime(tokenValue)) {
                tokenValue = tokenValue.toLocaleString();
              }

              return tokenValue;
            },
          );

          // Only append if it hasn't been appended before
          if (!item.name.includes(appendString)) {
            item.name += appendString;
          }
        }

        let values = {
          key: this.generateRandomKey(),
          index: index,
          description: item.name,
          qty: item.quantity,
          price: item.price,
          subtotal: item.amount,
          sku: preferences?.productsEnabled ? item.hs_sku : null,
          discountAmount: item?.discount ? item.discount * item.quantity : null,
          discountRate: item?.hs_discount_percentage
            ? item?.hs_discount_percentage
            : null,
          sourceHubspotId: item.hs_object_id,
        };

        if (connectionType === "xero" && !preferences?.productsEnabled) {
          const dealSettings = this.state?.deal?.settings;
          if (dealSettings?.account) {
            values.account = dealSettings?.account;
          }
          if (dealSettings?.tax) {
            values.tax = dealSettings?.tax;
          }
          if (dealSettings?.taxType) {
            values.taxType = dealSettings?.taxType;
          }
        }

        if (preferences?.trackingByLineAutoFill && connectionType === "xero") {
          for (let categoryToFill of preferences.trackingByLineAutoFill) {
            let valueFromHubSpot = item[categoryToFill.value];
            if (valueFromHubSpot) {
              values?.tracking || (values.tracking = {});
              let trackingCategory = this.state.tracking.find(
                (x) => x.name === categoryToFill.name,
              );
              if (trackingCategory) {
                let trackingOption = trackingCategory.options.find(
                  (x) => x.Name === valueFromHubSpot,
                );
                if (trackingOption) {
                  values.tracking[categoryToFill.name] = valueFromHubSpot;
                }
              }
            }
          }
        }

        if (
          preferences?.productsEnabled &&
          !item.reference &&
          item.price >= 0
        ) {
          values.error = "reference";
          values.errorDetails = "Select an item";
        }
        // TODO - copy the rest from existing autofill function
        return values;
      });
    return lineItems;
  }

  updateInvoiceCount(val) {
    var invoices = this.state.invoices;
    var length = invoices.length;
    while (length > val) {
      this.removeInvoice(val);
      length--;
    }
    while (length < val) {
      this.addInvoice();
      length++;
    }
    this.setInvoiceState(invoices);
  }

  updateTotals(changed = true) {
    let invoices = this.state.invoices;
    let deal = this.state.deal;

    invoices.forEach((invoice) => {
      // Calculate Total
      invoice.total = invoice.line_items.reduce((sum, item) => {
        if (!item.subtotal) {
          return sum;
        }
        return sum + Number(item.subtotal);
      }, 0);

      // Calculate Discounted totals for QBO Invoices
      if (
        invoice?.discount?.value &&
        this.state.connectionData.connectionType === "qbo"
      ) {
        // Quickbooks has a "whole invoice" discount option
        // This means all discounts have to be spread out per line, before being calculated for tax purposes
        // With Xero, this would just be added to the subtotal, but instead we're doing to put the value in item.discountedTotal
        if (invoice.discount.value.toString().includes("%")) {
          // calculate discounted total
          let discount = invoice.discount.value.split("%")[0];
          discount = this.round(discount, 2);
          invoice.line_items.forEach((item) => {
            item.discountedTotal =
              item.subtotal - item.subtotal * (discount / 100);
          });
        } else {
          let discount = invoice.discount.value;
          invoice.line_items.forEach((item) => {
            let discountRatio = item.subtotal / invoice.total;
            item.discountedTotal = this.round(
              item.subtotal - discount * discountRatio,
              2,
            );
          });
        }
      }

      if (invoice?.taxType === "TAX") {
        // taxType "TAX" means it will be autocalculated by QBO, using their AST engine.
        // If netTaxableAmountFromTaxDetails (from DB) and netTaxableAmountFromLineItems (updates in the frontend) are the same,
        // that means the tax calculation is correct.
        // However if they are different, the tax will need to be recalculated, as this is done by QBO, we do not know what this will be,
        // therefore set the tax to 0.
        const netTaxableAmountFromTaxDetails =
          invoice.tax_details?.TaxLine?.[0]?.TaxLineDetail?.NetAmountTaxable ||
          0;
        const netTaxableAmountFromLineItems = invoice.line_items
          .filter((item) => item.taxType === "TAX")
          .reduce((sum, item) => sum + item.subtotal, 0);

        invoice.totalTax =
          invoice.totalTax &&
          netTaxableAmountFromLineItems === netTaxableAmountFromTaxDetails
            ? Number(invoice.totalTax)
            : 0;
      }

      // Calculate Taxes
      if (
        this.state.taxes &&
        invoice?.line_items &&
        invoice?.taxType !== "TAX"
      ) {
        // Build an array of tax rates based on each line item's taxType
        let taxableData = [];
        let taxTypeList = [];

        const { connectionType, country } = this.state.connectionData;
        const isUsQbo = country === "US" && connectionType === "qbo";

        // Get unique taxType values
        taxTypeList = isUsQbo
          ? [invoice.taxType]
          : [...new Set(invoice.line_items.map((item) => item.taxType))];
        taxTypeList = taxTypeList.filter((type) => type); // remove false values

        // For each taxType
        if (taxTypeList.length) {
          taxTypeList.forEach((taxType) => {
            // get the total of all line items of that tax type
            let taxableTotal = invoice.line_items.reduce((sum, item) => {
              if (
                (!isUsQbo && item?.taxType !== taxType) ||
                (isUsQbo && (!item?.taxType || item.taxType === "NON"))
              ) {
                return sum;
              } else {
                let subtotal = item?.discountedTotal
                  ? Number(item.discountedTotal)
                  : Number(item.subtotal);
                return sum + this.round(subtotal, 2);
              }
            }, 0);

            // get the taxRate details
            let taxData = this.state.taxes.find((x) => x.type === taxType);
            let totalTax = this.round(
              taxData?.rate ? taxableTotal * (taxData.rate / 100) : 0,
              2,
            );
            taxableData.push({
              taxType: taxType,
              taxableTotal: taxableTotal,
              totalTax: totalTax,
              taxRate: taxData?.rate ? taxData.rate : 0,
              rateCode: taxData?.rateCode ? taxData.rateCode : false,
            });
          });
        }
        invoice.totalTax = taxableData.length
          ? taxableData.reduce((sum, item) => sum + item.totalTax, 0)
          : 0;

        invoice.taxDetails = taxableData;
      }

      /* Calculate the discount total and the new invoice total, after discount */
      if (Object.hasOwn(invoice, "discount") && invoice.discount.value) {
        if (invoice.discount.value.toString().includes("%")) {
          let discount = invoice.discount.value.split("%")[0];
          discount = this.round(discount, 2);
          invoice.discount.total = this.round(
            invoice.total * (discount / 100),
            2,
          );
        } else {
          invoice.discount.total = invoice.discount.value;
        }
        invoice.total = invoice.total - invoice.discount.total;
      }

      invoice.totalIncTax = this.round(invoice.total + invoice.totalTax, 2);
      invoice.total = this.round(invoice.total, 2);

      if (invoice.repeating) {
        const now = DateTime.now();
        const startDate = DateTime.fromISO(invoice.scheduleStartDate);

        // If the start date is in the past and there are no linked invoices matching the start date,
        // we need to calculate the total that will be backdated.
        invoice.backdatedTotal = 0;
        if (startDate < now) {
          const hasLinkedInvoiceForStartDate = invoices.find((i) => {
            const isLinkedInvoice =
              invoice.external_id &&
              i.repeating_invoice_id === invoice.external_id;
            const startDatesMatch = i.date === invoice.scheduleStartDate;

            return isLinkedInvoice && startDatesMatch;
          });

          if (!hasLinkedInvoiceForStartDate) {
            const interval = Interval.fromDateTimes(startDate, now);
            const unit = invoice.scheduleUnit === "WEEKLY" ? "weeks" : "months";
            const length = interval.length(unit);
            const count = Math.floor(length / invoice.schedulePeriod) + 1;

            invoice.backdatedTotal = count * Number(invoice.total);
          }
        }

        if (invoice.scheduleEndDate) {
          const now = DateTime.now();
          const endDate = DateTime.fromISO(invoice.scheduleEndDate);
          const nextInvoiceDate = this.getNextInvoiceDate(invoice);

          if (endDate < now || endDate < nextInvoiceDate) {
            invoice.futureTotal = 0;
          } else {
            const unit = invoice.scheduleUnit === "WEEKLY" ? "weeks" : "months";

            const totalInterval = Interval.fromDateTimes(startDate, endDate);
            const totalLength = totalInterval.length(unit);
            const totalCount =
              Math.floor(totalLength / invoice.schedulePeriod) + 1;

            let pastCount = 0;
            if (startDate < now) {
              const pastInterval = Interval.fromDateTimes(startDate, now);
              const pastLength = pastInterval.length(unit);
              pastCount = Math.floor(pastLength / invoice.schedulePeriod) + 1;
            }

            const count = totalCount - pastCount;
            invoice.futureTotal = count * Number(invoice.total);
          }
        } else {
          invoice.futureTotal = startDate < now ? 0 : Number(invoice.total);
        }
      }
    });

    const invoiceTotal = invoices.reduce(function (sum, i) {
      const total = Number(i.total);
      const creditExcTax = i?.creditExcTax ? Number(i.creditExcTax) : 0;

      return i.status !== "VOIDED" && i.status !== "DELETED" && !i.repeating
        ? sum + total - creditExcTax
        : sum;
    }, 0);

    const futureTotal = invoices.reduce(function (sum, i) {
      return i.status !== "VOIDED" && i.status !== "DELETED" && i.repeating
        ? sum + Number(i.futureTotal)
        : sum;
    }, 0);

    const backdatedTotal = invoices.reduce(function (sum, i) {
      return i.status !== "VOIDED" && i.status !== "DELETED" && i.repeating
        ? sum + Number(i.backdatedTotal)
        : sum;
    }, 0);

    deal.invoiceTotal = this.round(invoiceTotal, 2);
    deal.projectedTotal = this.round(
      invoiceTotal + futureTotal + backdatedTotal,
      2,
    );

    deal.invoiceTotalIncTax = invoices.reduce(function (sum, i) {
      return i.status !== "VOIDED" && i.status !== "DELETED"
        ? sum +
            Number(i.totalIncTax) -
            (i?.creditIncTax ? Number(i.creditIncTax) : 0)
        : sum;
    }, 0);

    deal.invoiceTotalIncTax = this.round(deal.invoiceTotalIncTax, 2);

    this.setState({ invoices: invoices, deal: deal, changed: changed });
  }

  updateDueDate(invoice) {
    const terms = this.state.deal?.settings?.terms;
    const type = terms?.Type || terms?.type;
    const day = terms?.Day || terms?.day;

    if (!type || !day) {
      return invoice;
    }

    const canUpdate = this.canUpdateInvoice(invoice);
    if (!canUpdate) {
      return invoice;
    }

    if (!invoice?.repeating) {
      let date = DateTime.fromISO(invoice.date);
      let dueDate;
      if (!invoice?.customDueDate && !invoice.repeating_invoice_id) {
        if (type === "OFFOLLOWINGMONTH" || type === "DAYSAFTERBILLMONTH") {
          dueDate = date.startOf("month").plus({ months: 1, days: day - 1 });
        } else if (type === "OFCURRENTMONTH") {
          dueDate = date.startOf("month").plus({ days: day - 1 });
        } else if (type === "DAYSAFTERBILLDATE") {
          dueDate = date.plus({ days: day });
        }
        invoice.due_date = dueDate.toISODate();
      }
    } else {
      invoice.terms = { day, type };
    }

    return invoice;
  }

  updateDueDates() {
    let invoices = this.state.invoices;

    invoices.forEach((invoice, index) => {
      invoices[index] = this.updateDueDate(invoice);
    });

    this.setState({ invoices: invoices });
  }

  addInvoice(isRepeating = false, position = "end") {
    const connectionType = this.state?.connectionData?.connectionType;
    const country = this.state?.connectionData?.country;
    const isQboUs = country === "US" && connectionType === "qbo";

    let invoices = this.state.invoices;
    let deal = this.state.deal;
    let values = {
      id: 0,
      repeating: isRepeating,
      key: this.generateRandomKey(),
      date: DateTime.now().toISODate(),
      due_date: "",
      total: 0,
      account: deal.settings.account !== "" ? deal.settings.account : false,
      totalIncTax: 0,
      currency: deal.settings.currency !== "" ? deal.settings.currency : "",
      custom_reference: deal.settings?.custom_reference
        ? deal.settings.custom_reference
        : "",
      custom_notes: deal.settings?.custom_notes
        ? deal.settings.custom_notes
        : "",
      status: this.state.preferences?.statuses?.default
        ? this.state.preferences.statuses.default
        : "DRAFT",
      external_id: "",
      useDefaultCustom_reference: true,
      useDefaultCustom_notes: true,
      useDefaultCustom_1: true,
      useDefaultCustom_2: true,
      useDefaultCustom_3: true, // TO DO - do this based on settings
      useDefaultCustom_message: true,
      attachments: [],
      line_items: [
        {
          key: this.generateRandomKey(),
          index: 0,
          description: "",
          qty: 1,
          price: 0,
          subtotal: 0,
          tax: deal.settings?.tax || 0,
          taxType: isQboUs ? "NON" : deal.settings?.taxType || false,
          account: deal.settings?.account || "NONE",
          tracking: {},
        },
      ],
    };

    if (isQboUs) {
      values.taxType = deal.settings?.taxType || false;
    }

    if (isRepeating) {
      values.scheduleUnit = "WEEKLY";
      values.schedulePeriod = 1;
      values.terms = {
        day: deal.settings?.terms?.day ? deal.settings?.terms?.day : 1,
        type: deal.settings?.terms?.type
          ? deal.settings?.terms?.type
          : "DAYSAFTERBILLDATE",
      };
    }
    values.customTaxes = this.doesInvoiceHaveCustomTaxes(values, deal);

    values = this.updateDueDate(values);
    if (position === "end") {
      invoices.push(values);
    } else {
      invoices.unshift(values);
    }
    this.setState({ invoices: invoices });
    const invoiceKey = values.key;
    const invoiceIndex = invoices.length - 1;
    return { invoiceKey, invoiceIndex };
  }

  addRecurringInvoice() {
    let modeSettings = this.state.deal.settings.modeSettings[0];
    let count;
    if (!Object.hasOwn(modeSettings, "count")) {
      count = modeSettings.months;
    } else {
      count = modeSettings.count;
    }
    count++;
    modeSettings.count = count;
    this.updateSetting("modeSettings", [modeSettings]);
    this.updateInvoiceCount(count);
  }

  removeInvoice(invoiceIndex) {
    let invoices = this.state.invoices;
    let removedInvoices = invoices.splice(invoiceIndex, 1);
    // Don't remove invoices that have already been synced with Xero
    if (
      removedInvoices[0].external_id !== "" &&
      removedInvoices[0].external_id !== null
    ) {
      this.setError("Error: Invoices already synced to Xero cannot be removed");
      invoices.splice(invoiceIndex, 0, removedInvoices[0]);
    }
    if (Object.hasOwn(this.state.deal.settings, "modeSettings")) {
      let modeSettings = this.state.deal.settings.modeSettings[0];
      modeSettings.count = invoices.length;
      this.updateSetting("modeSettings", [modeSettings]);
    }
    this.setState({ invoices: invoices });
    this.updateTotals();
  }

  async refreshInvoice() {
    const externalInvoiceId = this.state.confirmRefreshInvoice;

    try {
      this.setState({
        loadingInline: true,
        loadingTitle: "Refreshing invoice...",
        loadingSync: true,
        confirmRefreshInvoice: null,
      });

      const response = await fetch(
        `${api}/invoice/refresh/${externalInvoiceId}`,
        {
          headers: {
            "Content-Type": "application/json",
            accesstoken: accessToken,
          },
        },
      );

      const json = await response.json();

      if (json.error) {
        throw new Error(json.error);
      }

      const invoices = this.processInvoices(json);
      const deal = this.processDeals(json.deal);
      const invoiceErrors = this.state.invoiceErrors.filter(
        (e) => e.invoice.external_id !== externalInvoiceId,
      );
      this.setState({ invoices, deal, invoiceErrors });

      this.updateTotals();
      this.setSuccess("Invoice refetched");
    } catch {
      this.setError("Could not refetch this invoice");
    }
  }

  confirmRefreshInvoice(externalInvoiceId) {
    this.setState({ confirmRefreshInvoice: externalInvoiceId });
  }

  confirmCancelInvoice(invoiceId) {
    this.setState({ confirmCancelInvoice: invoiceId });
  }

  confirmReplaceInvoice(invoiceId) {
    this.setState({ confirmReplaceInvoice: invoiceId });
  }

  getNextInvoiceDate(invoice) {
    const now = DateTime.now().startOf("day");
    const startDate = DateTime.fromISO(invoice.scheduleStartDate).startOf(
      "day",
    );

    if (startDate > now) {
      return startDate;
    }

    const unit = invoice.scheduleUnit === "WEEKLY" ? "weeks" : "months";
    const pastInterval = Interval.fromDateTimes(startDate, now);
    const pastLength = pastInterval.length(unit);
    const pastUnits = Math.floor(pastLength / invoice.schedulePeriod);

    const unitsToNextInvoiceDate = (pastUnits + 1) * invoice.schedulePeriod;

    let nextInvoiceDate;
    if (invoice.scheduleUnit === "WEEKLY") {
      nextInvoiceDate = startDate.plus({ weeks: unitsToNextInvoiceDate });
    } else {
      nextInvoiceDate = startDate.plus({ months: unitsToNextInvoiceDate });
    }

    return nextInvoiceDate;
  }

  replaceInvoice() {
    const invoiceId = this.state.confirmReplaceInvoice;

    const invoice = this.state.invoices.find(
      (invoice) => invoice.id === invoiceId,
    );

    const nextInvoiceDate = this.getNextInvoiceDate(invoice);

    const newInvoice = JSON.parse(JSON.stringify(invoice));
    newInvoice.previous_id = invoice.id;
    newInvoice.key = this.generateRandomKey();
    newInvoice.scheduleStartDate = nextInvoiceDate.toISODate();
    newInvoice.hasNewStartDate =
      newInvoice.scheduleStartDate !== invoice.scheduleStartDate;

    delete newInvoice.id;
    delete newInvoice.external_id;
    delete newInvoice.external_invoicenumber;

    newInvoice.line_items.map((line) => {
      line.key = this.generateRandomKey();
      delete line.qboId;
      delete line.xeroId;
      return line;
    });

    newInvoice.attachments = newInvoice.attachments
      .filter((attachment) => Object.hasOwn(attachment, "hubspot_id"))
      .map((attachment) => ({
        hubspot_id: attachment.hubspot_id,
        filename: attachment.filename,
      }));

    const updated = this.state.invoices.map((invoice) => {
      if (invoice.id === invoiceId) {
        invoice.status = "DELETED";
      }

      return invoice;
    });

    this.setState({
      invoices: [...updated, newInvoice],
      confirmReplaceInvoice: null,
    });
  }

  async cancelInvoice() {
    const invoiceId = this.state.confirmCancelInvoice;

    try {
      this.setState({
        loadingInline: true,
        loadingTitle: "Cancelling repeating invoice...",
        loadingSync: true,
      });

      const response = await fetch(
        `${api}/repeating-invoices/cancel/${invoiceId}`,
        {
          headers: {
            "Content-Type": "application/json",
            accesstoken: accessToken,
          },
        },
      );

      const json = await response.json();

      if (json.error) {
        throw new Error(json.error);
      }

      const updated = this.state.invoices.map((invoice) => {
        if (invoice.id === invoiceId) {
          invoice.status = "DELETED";
        }

        return invoice;
      });

      this.setState({ invoices: updated });
      this.updateTotals();
      this.setSuccess("Repeating invoice cancelled");
    } catch {
      this.setError("Could not cancel this repeating invoice");
    } finally {
      this.setState({ confirmCancelInvoice: null });
    }
  }

  async uploadAttachment(item) {
    try {
      this.setState({
        showUploadStatus: true,
      });
      const response = await fetch(`${api}/attachments/upload`, {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
        method: "post",
        body: JSON.stringify(item),
      });

      const json = await response.json();

      if (json.error) {
        throw new Error(json.error);
      }
    } catch {
      this.setError("Could not reupload this attachment");
    }
  }

  async deleteAttachment(item) {
    try {
      this.setState({
        showUploadStatus: true,
      });
      const response = await fetch(`${api}/attachments/delete`, {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
        method: "post",
        body: JSON.stringify(item),
      });

      const json = await response.json();

      if (json.error) {
        throw new Error(json.error);
      }
    } catch {
      this.setError("Could not remove this attachment");
    }
  }

  cloneInvoice(invoiceIndex) {
    let invoices = this.state.invoices;
    let sourceInvoice = invoices[invoiceIndex];
    let newInvoice = JSON.parse(JSON.stringify(sourceInvoice));
    let newLineItems = [];
    newInvoice.line_items.forEach((line) => {
      line.key = this.generateRandomKey();
      delete line.qboId;
      delete line.xeroId;
      newLineItems.push(line);
    });
    newInvoice.key = this.generateRandomKey();
    newInvoice.id = 0;
    newInvoice.external_id = "";
    newInvoice.external_invoicenumber = "";
    newInvoice.status = "DRAFT";
    newInvoice.sent = false;
    newInvoice.external_url = false;
    newInvoice.attachments = newInvoice.attachments
      .filter((attachment) => Object.hasOwn(attachment, "hubspot_id"))
      .map((attachment) => ({
        hubspot_id: attachment.hubspot_id,
        filename: attachment.filename,
      }));

    invoices.push(newInvoice);
    this.setState({ invoices: invoices });
    this.updateTotals();
  }
  updateInvoice(invoiceIndex, type, val) {
    let invoices = this.state.invoices;
    let invoice = invoices[invoiceIndex];
    if (typeof val === "object" && val !== null && !Array.isArray(val)) {
      let current = invoice[type];
      val = { ...current, ...val }; // if objects then merge them together, new value overwrites old
    }
    if (type === "due_date") {
      invoice.customDueDate = true;
    }
    invoice[type] = val;
    if (type === "date") {
      invoice = this.updateDueDate(invoice);
    }
    invoices[invoiceIndex] = invoice;
    this.setState({ invoices: invoices });

    if (
      this.state.deal.settings.mode === "recurring" ||
      this.state.deal.settings.mode === "Monthly"
    ) {
      if (type === "date") {
        //If editing the master deal date then trigger an update on all the dates
        let modeSettings = this.state.deal.settings.modeSettings[0];
        modeSettings.startDate = val;
        this.updateSetting("modeSettings", [modeSettings]);
        this.mirrorInvoice(0);
        this.updateDueDates();
      } else {
        this.mirrorInvoice(0);
      }
    }
    if (this.state.deal.settings.mode === "deposit_recurring") {
      if (type === "date" && invoiceIndex === 1) {
        //If editing the master deal date then trigger an update on all the dates
        let modeSettings = this.state.deal.settings.modeSettings[0];
        modeSettings.startDate = val;
        this.updateSetting("modeSettings", [modeSettings]);
        this.mirrorInvoice(1);
        this.updateDueDates();
      } else {
        this.mirrorInvoice(1);
      }
    }
    this.updateTotals();
  }

  mirrorInvoice(masterInvoiceKey) {
    let invoices = this.state.invoices;
    let masterInvoice = invoices[masterInvoiceKey];
    if (typeof masterInvoice !== "undefined") {
      let settings = this.state.deal.settings;
      let startDate = DateTime.fromISO(masterInvoice.date);
      invoices.forEach((invoice, index) => {
        if (index > masterInvoiceKey) {
          //Do not update invoices if they are already set to authorised
          if (
            ["AUTHORISED", "PAID", "VOIDED", "DELETED"].includes(invoice.status)
          ) {
            this.setError(
              "Warning: some of your recurring invoices are already saved in Xero and will not be updated",
            );

            // TODO show some kind of error message if this happens
          } else {
            invoice.total = masterInvoice.total;
            invoice.totalIncTax = masterInvoice.totalIncTax;
            invoice.currency = masterInvoice.currency;
            invoice.customTaxes = masterInvoice.customTaxes;
            invoice.line_items = masterInvoice.line_items; // TODO - keys?

            if (masterInvoice?.taxType) {
              invoice.taxType = masterInvoice.taxType;
            }

            if (startDate) {
              let position =
                settings.mode === "deposit_recurring" ? index - 1 : index;
              if (
                settings.modeSettings[0].period === "month" ||
                settings.mode === "Monthly"
              ) {
                invoice.date = startDate.plus({ months: position }).toISODate();
              } else if (settings.modeSettings[0].period === "quarter") {
                invoice.date = startDate
                  .plus({ months: 3 * position })
                  .toISODate();
              } else if (settings.modeSettings[0].period === "year") {
                invoice.date = startDate.plus({ year: position }).toISODate();
              }
            }
          }
        }
        invoices[index] = invoice;
      });
      this.setState({ invoices: invoices });
    }
  }

  splitInvoice(invoiceIndex, splitData) {
    let invoices = this.state.invoices;
    let sourceInvoice = invoices[invoiceIndex];
    let percentages = splitData.percentages
      .split("%")
      .filter((i) => parseFloat(i));
    percentages.forEach((percentage, index) => {
      let newInvoice = { ...sourceInvoice };
      let newLineItems = [];
      let ratio = parseFloat(percentage / 100);
      newInvoice.line_items.forEach(({ ...item }) => {
        item.key = this.generateRandomKey();
        delete item.qboId;
        delete item.xeroId;

        if (splitData?.splitQty) {
          item.qty = item.qty * ratio;
        } else {
          item.price = item.price * ratio;
        }
        if (item.discountAmount) {
          item.discountAmount = item.discountAmount * ratio;
          item.subtotal = item.price * item.qty - item.discountAmount;
        } else if (item.discountRate) {
          let discountDecimal = (100 - item.discountRate) / 100;
          item["subtotal"] = item.price * item.qty * discountDecimal;
        } else {
          item["subtotal"] = item.price * item.qty;
        }
        newLineItems.push(item);
      });
      newInvoice.line_items = newLineItems;
      if (index !== 0) {
        newInvoice.key = this.generateRandomKey();
        newInvoice.id = 0;
        newInvoice.external_id = "";
        newInvoice.external_invoicenumber = "";
        newInvoice.status = "";
        newInvoice.sent = false;
        newInvoice.external_url = false;
        newInvoice.attachments = [];
      } else {
        invoices.splice(invoiceIndex, 1);
      }
      invoices.push(newInvoice);
    });
    this.setState({ invoices: invoices });
    this.updateTotals();
    this.setSuccess("Payment Schedule applied");
  }

  async createDeposit(sourceKey, depositTotal, depositDate, balanceDate) {
    try {
      let { invoiceKey } = await this.addInvoice(false, "start");
      let invoices = this.state.invoices;
      let sourceInvoice = invoices.find((invoice) => invoice.key === sourceKey);
      let depositInvoice = invoices.find(
        (invoice) => invoice.key === invoiceKey,
      );

      depositInvoice.line_items = [
        {
          key: this.generateRandomKey(),
          description: "Deposit",
          qty: 1,
          price: depositTotal,
          subtotal: depositTotal,
        },
      ];
      depositInvoice.date = depositDate;

      sourceInvoice.line_items = [
        ...sourceInvoice.line_items,
        {
          key: this.generateRandomKey(),
          description: "Less Deposit",
          qty: 1,
          price: -depositTotal,
          subtotal: -depositTotal,
        },
      ];
      sourceInvoice.date = balanceDate;

      this.setState({ invoices: invoices });
      this.updateTotals();
      this.setSuccess("Deposit invoice created");
    } catch {
      this.setError("Could not create deposit invoice");
    }
  }

  setInvoiceState(value) {
    if (
      this.state.deal.settings.mode === "recurring" ||
      this.state.deal.settings.mode === "Monthly"
    ) {
      this.mirrorInvoice(0);
    } else if (this.state.deal.settings.mode === "deposit_recurring") {
      this.mirrorInvoice(1);
    }
    this.setState({ invoices: value });
    this.updateTotals();
  }

  canUpdateInvoice = (invoice, prop) => {
    const taxProps = ["tax", "taxType", "account"];
    if (taxProps.includes(prop) && invoice.customTaxes) {
      return false;
    }

    const connectionType = this.state.connectionData?.connectionType;
    const isXeroRepeating = invoice.repeating && connectionType === "xero";

    if (invoice?.external_id && isXeroRepeating) {
      return false;
    }

    const uneditableStatuses = [
      "AUTHORISED",
      "PAID",
      "SENT",
      "VOIDED",
      "DELETED",
    ];
    const hasUneditableStatus = uneditableStatuses.includes(invoice.status);

    if (invoice?.external_id && hasUneditableStatus) {
      return false;
    }

    const isFromRepeatingInvoice =
      invoice?.repeating_invoice_id &&
      this.state.invoices.find(
        (i) => i.external_id === invoice.repeating_invoice_id,
      );

    if (isFromRepeatingInvoice) {
      return false;
    }

    return true;
  };

  addAttachmentsToAllInvoices(attachments) {
    const invoices = this.state.invoices;

    for (const [index, invoice] of invoices.entries()) {
      const canUpdate = this.canUpdateInvoice(invoice, name);

      if (!canUpdate) {
        continue;
      }

      const existingAttachments = invoice.attachments || [];
      const newAttachments = [
        ...existingAttachments,
        ...attachments.filter(
          (a) =>
            !existingAttachments.find((e) => e.hubspot_id === a.hubspot_id),
        ),
      ];

      this.updateInvoice(index, "attachments", newAttachments);
    }
  }

  updateAllInvoices(name, val) {
    const invoices = this.state.invoices;

    for (const [index, invoice] of invoices.entries()) {
      const canUpdate = this.canUpdateInvoice(invoice, name);

      if (!canUpdate) {
        continue;
      }

      this.updateInvoice(index, name, val);

      // taxType "TAX" means it will be autocalculated by QBO, using their AST engine.
      // Therefore we cannot know or calculate the totalTax, so set it to 0.
      if (name === "taxType" && val === "TAX") {
        this.updateInvoice(index, "totalTax", 0);
      }
    }
  }

  updateAllLineItems(name, val) {
    const invoices = this.state.invoices;

    for (const [index, invoice] of invoices.entries()) {
      const canUpdate = this.canUpdateInvoice(invoice, name);

      if (!canUpdate) {
        continue;
      }

      invoice.line_items.forEach((item) => {
        item[name] = val;
      });
      invoices[index] = invoice;

      this.setState({ invoices: invoices });
    }
  }

  getTaxFromTaxType(taxType) {
    let tax = this.state.taxes.find((x) => x.type === taxType);

    if (typeof tax == "undefined") {
      tax = {};
      tax.rate = 0;
      tax.type = false;
    } else if (!Object.hasOwn(tax, "rate")) {
      tax.rate = 0;
      tax.type = "NONE";
    }

    return tax;
  }

  updateDefaultTax(tax) {
    const invoices = this.state.invoices;

    for (const [index, invoice] of invoices.entries()) {
      const canUpdate = this.canUpdateInvoice(invoice);

      if (!canUpdate) {
        continue;
      }

      const country = this.state?.connectionData?.country;
      const connectionType = this.state?.connectionData?.connectionType;
      const isQboUs = country === "US" && connectionType === "qbo";

      if (isQboUs) {
        this.updateInvoice(index, "taxType", tax.type);

        // taxType "TAX" means it will be autocalculated by QBO, using their AST engine.
        // Therefore we cannot know or calculate the totalTax, so set it to 0.
        if (tax.type === "TAX") {
          this.updateInvoice(index, "totalTax", 0);
        }
      } else {
        invoice.line_items.forEach((item) => {
          item.taxType = tax.type;
          item.tax = tax.rate;
        });
        invoices[index] = invoice;

        this.setState({ invoices: invoices });
      }
    }
  }

  updateSetting(name, val, updateInvoices, updateLineItems = false) {
    console.log(
      "updating " +
        name +
        " with " +
        val +
        " invoices = " +
        !!updateInvoices +
        " and line items = " +
        updateLineItems,
    );

    if (name === "tax") {
      val = Number(val);
    }

    let deal = this.state.deal;
    deal.settings[name] = val;
    this.setState({ deal: deal });

    if (name === "terms") {
      this.updateDueDates();
    }

    if (name === "contactHasCustomTax") {
      const tax = this.getTaxFromTaxType(val);
      this.updateSetting("taxType", tax.type);
      this.updateSetting("tax", tax.rate);
      this.updateDefaultTax(tax);
    }

    if (updateInvoices) {
      this.updateAllInvoices(name, val);
    }

    if (updateLineItems) {
      this.updateAllLineItems(name, val);
    }

    this.updateTotals();
  }

  globalData(name, val) {
    let current = this.state[name];
    if (typeof val === "object" && val !== null && !Array.isArray(val)) {
      val = { ...current, ...val }; // if objects then merge them together, new value overwrites old
    }
    this.setState({ [name]: val });
    this.updateTotals(false);
  }

  async saveInvoices() {
    try {
      this.setState({ saveStatus: "in_progress" });

      let data = {
        deal: this.state.deal,
        invoices: this.state.invoices,
      };

      await fetch(api + "/invoices/save", {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
        method: "post",
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            throw res.error;
          }

          //Need to do this to add the ID of the invoice back in
          let invoices = this.processInvoices(res);
          let deal = this.processDeals(res.deal);

          if (!this.eventSource && this.state?.allowAttachments) {
            this.createEventSource(deal.deal_id);
          }

          this.setState({
            invoices: invoices,
            deal: deal,
          });
          this.updateTotals(false);
          this.setState({ saveStatus: "complete" });
        })
        .catch((error) => {
          throw error;
        });
    } catch {
      this.setState({ saveStatus: "error" });
      if (this.state.loadingSync) {
        throw "Could not save invoices";
      } else {
        this.setError("Could not save invoices");
      }
    }
  }

  async syncInvoices() {
    try {
      await this.setState({
        loadingSync: true,
        syncStatus: "in_progress",
      });
      // Validation Check that a company has been added
      if (
        this.state.deal.settings.contact === "Choose One" ||
        !this.state.deal.settings.contact
      ) {
        throw "No company selected";
      }

      // Validation - invoice dates and line item error messages
      // Do not apply validation to authorised/void or deleted invoices that have external ids
      let approvedStatuses = ["AUTHORISED", "SUBMITTED", "APPROVED"];
      let currentInvoices = this.state.invoices.filter((invoice) => {
        return (
          (approvedStatuses.includes(invoice.status) && !invoice.external_id) ||
          invoice.status === "DRAFT"
        );
      });
      for (let i = 0; i < currentInvoices.length; i++) {
        if (
          currentInvoices[i].date === "" &&
          currentInvoices[i].status === "" &&
          !currentInvoices[i]?.repeating
        ) {
          throw "Invoice dates not entered";
        }
        if (currentInvoices[i].line_items.some((i) => i["error"])) {
          throw "Some line items have errors";
        }
        if (
          this.state.connectionData.connectionType === "xero" &&
          currentInvoices[i].repeating &&
          currentInvoices[i].line_items.some(
            (line) => !line?.account || line.account === "NONE",
          )
        ) {
          throw "Check your line items. Account must be set for repeating invoices";
        }
        if (
          approvedStatuses.includes(currentInvoices[i].status) &&
          currentInvoices[i].line_items.some(
            (line) => !line?.account || line.account === "NONE",
          )
        ) {
          throw (
            "Account must be set if " +
            (currentInvoices[i].status === "SUBMITTED"
              ? "sending for approval"
              : "setting to authorised")
          );
        }
      }

      //if any repeating invoices start today and the connection is quickbooks, then set confirmQBOStartingToday to true and exit
      let invoices = this.state.invoices;
      let connectionData = this.state.connectionData;
      let confirmQBOStartingToday = false;
      if (connectionData.connectionType === "qbo") {
        invoices.forEach((invoice) => {
          if (
            invoice.repeating &&
            invoice.scheduleStartDate === DateTime.now().toISODate()
          ) {
            confirmQBOStartingToday = true;
          }
        });
      }
      if (confirmQBOStartingToday) {
        this.setState({ confirmQBOStartingToday: true });
        return;
      }

      await this.saveInvoices();

      const response = await fetch(api + "/invoices/sync", {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      });

      const json = await response.json();

      if (json.error) throw json.error;

      const processedInvoices = this.processInvoices(json);
      const deal = this.processDeals(json.deal);

      this.setState({
        invoices: processedInvoices,
        deal: deal,
        invoiceErrors: json.invoiceErrors,
      });

      if (json.invoiceErrors.length) {
        this.setState({ syncStatus: "error" });
        if (json.invoiceErrors.length !== json.invoices.length) {
          this.setError(
            "Error Syncing Invoices",
            true,
            `Some of your invoices could not be synced - click continue to see details`,
          );
        } else if (json.invoices.length === 1) {
          this.setError(
            "Error Syncing Invoice",
            true,
            `Your invoice could not be synced - click continue to see details`,
          );
        } else {
          this.setError(
            "Error Syncing Invoices",
            true,
            `Your invoices could not be synced - click continue to see details`,
          );
        }
      } else {
        this.setState({ syncStatus: "complete" });
      }

      this.updateTotals(false);
    } catch (error) {
      this.setState({ syncStatus: "error" });
      console.error(error);
      this.setError(error?.error ? error.error : error, true);
    }
  }

  getExistingAutofilledLineItems = () => {
    return this.state.invoices.reduce((acc, cur) => {
      const sourceHubspotIds = cur.line_items
        .filter((i) => i.sourceHubspotId)
        .map((i) => i.sourceHubspotId);
      if (sourceHubspotIds.length) {
        acc.push(...sourceHubspotIds);
      }

      return acc;
    }, []);
  };

  getAttachedAttachmentsHubspotIds = () => {
    const attachedAttachments = this.state.invoices.reduce((acc, cur) => {
      cur.attachments.forEach(
        (item) => item?.hubspot_id && acc.add(item.hubspot_id),
      );
      return acc;
    }, new Set());

    return [...attachedAttachments];
  };

  getAttachmentErrorsForAutoFill = (attachments) => {
    const attachmentErrors = [];

    attachments.forEach((a) => {
      if (a.errors.fileSize) {
        attachmentErrors.push(
          `We could not attach file ${a.name} as the file size is too large.`,
        );
      }
      if (a.errors.fileType) {
        attachmentErrors.push(
          `We could not attach file ${a.name} as this file type is not supported.`,
        );
      }
    });

    return attachmentErrors;
  };

  async autoFill(progressive = true) {
    try {
      let invoices = this.state.invoices;
      let preferences = this.state.preferences;
      let lineItemData = this.state.hubspotData.lineItemData;

      let attachments = [];
      let attachmentErrors = [];

      if (progressive && invoices.length) {
        const existingAutofilledLineItems =
          this.getExistingAutofilledLineItems();
        lineItemData = lineItemData.filter(
          (l) => !existingAutofilledLineItems.includes(l.hs_object_id),
        );
      }

      if (this.state?.allowAttachments && lineItemData.length) {
        try {
          attachments = await this.getAttachmentPropertyAttachments();
        } catch {
          attachmentErrors.push(
            "There was an issue getting your attachments from HubSpot.",
          );
        }

        if (progressive) {
          const attachedAttachments = this.getAttachedAttachmentsHubspotIds();
          attachments = attachments.filter(
            (a) => !attachedAttachments.includes(a.id),
          );
        }

        attachmentErrors = attachments.length
          ? this.getAttachmentErrorsForAutoFill(attachments)
          : attachmentErrors;
        attachments = attachments
          .filter((a) => !a.errors.fileSize && !a.errors.fileType)
          .map((a) => ({ hubspot_id: a.id, filename: a.name }));
      }

      let groupedLineItems;
      if (
        this.state.connectionData.plan !== "starter" &&
        !preferences?.disableRecurring
      ) {
        // Group the line items based on recurring options
        groupedLineItems = Object.values(
          lineItemData.reduce((r, item) => {
            // If you delete the start date, HubSpot sets it to '', not back to null
            const hs_recurring_billing_start_date =
              item.hs_recurring_billing_start_date === ""
                ? null
                : item.hs_recurring_billing_start_date;
            const recurringbillingfrequency =
              item.recurringbillingfrequency === ""
                ? null
                : item.recurringbillingfrequency;
            const hs_recurring_billing_period =
              item.hs_recurring_billing_period === ""
                ? null
                : item?.hs_recurring_billing_period;

            const key = `${recurringbillingfrequency}-${hs_recurring_billing_period}-${hs_recurring_billing_start_date}`;

            r[key] = r[key] || {
              recurringbillingfrequency,
              hs_recurring_billing_period,
              hs_recurring_billing_start_date,
              lineItems: [],
            };
            r[key]["lineItems"].push(item);
            return r;
          }, {}),
        );
      } else {
        groupedLineItems = Object.values(
          lineItemData.reduce((r, item) => {
            const hs_recurring_billing_start_date =
              item.hs_recurring_billing_start_date || null;
            const key = item.hs_recurring_billing_start_date || null;

            r[key] = r[key] || {
              hs_recurring_billing_start_date,
              lineItems: [],
            };
            r[key]["lineItems"].push(item);
            return r;
          }, {}),
        );
      }

      for (let i = 0; i < groupedLineItems.length; i++) {
        const group = groupedLineItems[i];
        let { invoiceIndex } = await this.addInvoice(
          Boolean(group.recurringbillingfrequency),
        );

        let values = {
          id: 0,
          key: this.generateRandomKey(),
          repeating: Boolean(group.recurringbillingfrequency),
          attachments,
        };

        // if QBO and one of the line items has a negative price, then add this as a discount to the values object
        if (this.state.connectionData.connectionType === "qbo") {
          let discount = group.lineItems.filter((item) => item.price < 0);

          if (discount.length > 0) {
            const totalDiscount = discount.reduce(
              (sum, item) => sum + item.price,
              0,
            );

            values.discount = {
              value:
                discount.length === 1 && discount[0].discountPercentage
                  ? discount[0].discountPercentage
                  : -1 * totalDiscount, // preserve percentage discount if just one
              total: -1 * totalDiscount,
              enabled: true,
            };
          }
        }

        values.line_items = this.processLineItems(group.lineItems, preferences);

        // If discounts are disabled in QBO, but a discount exists on the quote then allow discounts
        if (
          this.state.connectionData.connectionType === "qbo" &&
          values?.discount &&
          this.state.preferences?.discountEnabled === false
        ) {
          this.updateSetting("discountEnabled", true, true, true);
        }

        if (values.repeating) {
          values.scheduleStartDate = group?.hs_recurring_billing_start_date
            ? group?.hs_recurring_billing_start_date
            : DateTime.now().toISODate();

          // parse from P10D to 10 or P1M to 1 using a regex
          const termMatch =
            group?.hs_recurring_billing_period?.match(/(\d+)([A-Za-z])/);
          const termValue = termMatch ? termMatch[1] : null;
          const termUnit = termMatch ? termMatch[2] : null;

          values.scheduleEndDate = false;

          if (termUnit === "M") {
            values.scheduleEndDate = DateTime.fromISO(values.scheduleStartDate)
              .plus({ months: termValue })
              .minus({ day: 1 })
              .toISODate();
          } else if (termUnit === "D") {
            values.scheduleEndDate = DateTime.fromISO(values.scheduleStartDate)
              .plus({ days: termValue })
              .minus({ day: 1 })
              .toISODate();
          }

          if (group?.recurringbillingfrequency === "annually") {
            values.schedulePeriod = 12;
            values.scheduleUnit = "MONTHLY";
          } else if (group?.recurringbillingfrequency === "biweekly") {
            values.schedulePeriod = 2;
            values.scheduleUnit = "WEEKLY";
          } else if (group?.recurringbillingfrequency === "monthly") {
            values.schedulePeriod = 1;
            values.scheduleUnit = "MONTHLY";
          } else if (group?.recurringbillingfrequency === "quarterly") {
            values.schedulePeriod = 3;
            values.scheduleUnit = "MONTHLY";
          } else if (group?.recurringbillingfrequency === "per_six_months") {
            values.schedulePeriod = 6;
            values.scheduleUnit = "MONTHLY";
          } else if (group?.recurringbillingfrequency === "per_two_years") {
            values.schedulePeriod = 24;
            values.scheduleUnit = "MONTHLY";
          } else if (group?.recurringbillingfrequency === "per_three_years") {
            values.schedulePeriod = 36;
            values.scheduleUnit = "MONTHLY";
          }
        } else {
          values.date =
            group?.hs_recurring_billing_start_date ||
            DateTime.now().toISODate();
        }
        invoices[invoiceIndex] = { ...invoices[invoiceIndex], ...values };
      }

      this.setInvoiceState(invoices);
      this.updateDueDates();

      if (attachmentErrors.length) {
        this.setError("AutoFill problem", true, attachmentErrors);
      } else {
        this.setSuccess("AutoFill completed");
        this.updateSetting("autofill", "complete");
      }
    } catch (error) {
      console.error(error);
      this.setError("AutoFill problem");
    }
  }

  async doOldAutoFill() {
    let invoices = this.state.invoices;
    let preferences = this.state.preferences;
    let lineItemData = this.state.hubspotData.lineItemData;
    let targetInvoice = 0;
    // Look for existing invoices already synced to Xero with clear statuses
    let firstUnlockedInvoice = invoices.findIndex(
      (invoice) =>
        !(
          ["AUTHORISED", "PAID", "VOIDED", "DELETED"].includes(
            invoice.xero_status,
          ) && invoice?.id
        ),
    );
    if (this.state.deal.settings.mode === "deposit_recurring") {
      targetInvoice = 1;
    } else if (firstUnlockedInvoice >= 0) {
      //If there is an existing clear invoice, autofill to it
      targetInvoice = firstUnlockedInvoice;
    } else if (firstUnlockedInvoice === -1 && !invoices.length) {
      //If there are just no invoices at all, just add to the first one
      targetInvoice = 0;
    } else {
      //Otherwise, if there is a isn't a clear one then add an invoice ready to fill it into
      targetInvoice = invoices.length;
      await this.addInvoice();
      invoices = this.state.invoices;
    }

    let lineItems = [invoices[targetInvoice].line_items];
    if (this.state.deal.settings.mode === "deposit_recurring") {
      lineItems[1] = invoices[1].line_items;
    }

    //remove 1st empty line which is standard for a new invoice
    for (let i = 0; i < lineItems.length; i++) {
      if (
        lineItems[i][0] &&
        lineItems[i][0].description === "" &&
        lineItems[i][0].qty === 1 &&
        lineItems[i][0].price === 0
      ) {
        lineItems[i] = [];
      }
    }

    if (preferences?.dummyLineItem) {
      let description = "";
      if (preferences?.dummyLineItem?.property) {
        preferences.dummyLineItem.descriptionTokens =
          "#" + preferences.dummyLineItem.property;
      }
      if (preferences?.dummyLineItem?.descriptionTokens) {
        // Look for tokens starting with "#" in the string preferences.dummyLineItem.descriptionTokens, match them with keys in this.state.hubspotData.properties and replace with values
        description = preferences.dummyLineItem.descriptionTokens.replace(
          /#([a-z0-9_]+)/gi,
          (match, key) => {
            return this.state.hubspotData.properties[key] || match;
          },
        );
      }

      let data = {
        description: description,
        qty: 1,
        key: this.generateRandomKey(),
        price:
          preferences.dummyLineItem?.useDealAmount &&
          this.state.hubspotData.properties?.amount
            ? this.state.hubspotData.properties.amount
            : 0,
        subtotal:
          preferences.dummyLineItem?.useDealAmount &&
          this.state.hubspotData.properties?.amount
            ? this.state.hubspotData.properties.amount
            : 0,
      };
      if (preferences.dummyLineItem?.sku) {
        data.sku = preferences.dummyLineItem.sku;
      }
      lineItems[0][0] = data;
    }

    if (lineItemData.length) {
      //sort by position on quote
      lineItemData.sort((a, b) =>
        Number(a.hs_position_on_quote) > Number(b.hs_position_on_quote)
          ? 1
          : -1,
      );

      //TO DO catch if none
      lineItemData.forEach((item) => {
        let data = {
          description: item.name,
          qty: item.quantity,
          subtotal: item.amount,
          key: this.generateRandomKey(),
          price: item.price,
        };
        if (preferences?.productsEnabled) {
          data.sku = item.hs_sku;
        }
        if (preferences?.xero_account_code) {
          data.account = item[preferences.xero_account_code];
        } else {
          data.account = item.xero_account_code;
        }
        if (item.discount) {
          data.discountAmount = item.discount * item.quantity;
        }
        if (item.hs_discount_percentage) {
          data.discountRate = item.hs_discount_percentage;
        }
        // Choose relevant tax rate
        if (data.account && data.account !== "NONE" && this.state.accounts) {
          let accountData = this.state.accounts.find(
            (x) => x.code === data.account,
          );
          if (typeof accountData !== "undefined") {
            let tax = this.state.taxes.find(
              (x) => x.type === accountData.taxType,
            );
            if (typeof tax === "undefined") {
              data.tax = tax.type;
            }
          }
        }
        if (preferences?.productsEnabled && !item.reference) {
          item.error = "reference";
          item.errorDetails = "Select an item";
        }
        // Customisation: Append text to line item
        if (preferences?.line_item_append) {
          let tokens = {};
          let error = null;
          console.log("line item append");
          //Get deal properties and save to token list
          if (preferences?.line_item_append?.deal_properties) {
            preferences.line_item_append.deal_properties.forEach((prop) => {
              let data = this.state.hubspotData.properties[prop];
              if (!DateTime.fromISO(data).invalid) {
                data = DateTime.fromISO(data).toLocaleString(); //format to local date
              }
              if (data === null || data === "") {
                error = true;
              }
              tokens["#" + prop] = data;
            });
          }
          //Get line item properties and save to token list
          if (preferences?.line_item_append?.line_item_properties) {
            preferences.line_item_append.line_item_properties.forEach(
              (prop) => {
                let data = item[prop];
                if (!DateTime.fromISO(data).invalid) {
                  data = DateTime.fromISO(data).toLocaleString(); //format to local date
                }
                if (data === null || data === "") {
                  error = true;
                }
                tokens["#" + prop] = data;
              },
            );
          }
          // CUSTOM - for wave9
          if (preferences?.custom === "wave9") {
            // Use deal contract start date if line item start date is empty and skip error checks
            if (tokens["#hs_recurring_billing_start_date"] === null) {
              tokens["#hs_recurring_billing_start_date"] =
                tokens["#contract_start_date"];
              error = false;
            }
            // Skip replacement if recurring billing is not selected
            if (!tokens["#hs_recurring_billing_period"]) {
              error = true;
            }
          }
          if (!error) {
            //Regex replace any #tokens in the line item string and append to line item
            var re = new RegExp(Object.keys(tokens).join("|"), "gi");
            data.description =
              data.description +
              preferences.line_item_append.string.replace(
                re,
                function (matched) {
                  return tokens[matched.toLowerCase()];
                },
              );
          }
        }

        if (
          this.state.deal.settings.mode === "deposit_recurring" &&
          item.recurringbillingfrequency !== "" &&
          item.recurringbillingfrequency !== null
        ) {
          lineItems[1].push(data);
        } else {
          lineItems[0].push(data);
        }
        // Set the start date using the line item start date
        if (this.state.deal.settings.mode === "deposit_recurring") {
          if (item?.recurringbillingfrequency) {
            if (
              invoices[1].date === "" &&
              item.hs_recurring_billing_start_date
            ) {
              let date = item.hs_recurring_billing_start_date;
              this.updateInvoice(1, "date", date);
            }
          } else {
            if (
              invoices[0].date === "" &&
              item.hs_recurring_billing_start_date
            ) {
              let date = item.hs_recurring_billing_start_date;
              this.updateInvoice(0, "date", date);
            }
          }
        }
        if (
          invoices[0].date === "" &&
          item.hs_recurring_billing_start_date &&
          item.recurringbillingfrequency !== "" &&
          item.recurringbillingfrequency !== null &&
          this.state.deal.settings.mode !== "deposit_recurring"
        ) {
          let date = item.hs_recurring_billing_start_date;
          this.updateInvoice(0, "date", date);
        }
      });
    }

    // CUSTOM - for vuka
    if (preferences?.custom === "vuka") {
      // For certain teams
      let vukaDealTypes =
        this.state.hubspotData.properties.product_type.split(";");
      if (vukaDealTypes.includes("Live")) {
        let closeDate = DateTime.fromISO(
          this.state.hubspotData.properties.closedate,
        );
        let eventDate = DateTime.fromISO(
          this.state.hubspotData.properties.event_campaign_start_date,
        );
        let diff = eventDate.diff(closeDate, "days");
        if (diff.toObject().days >= 120) {
          // If 90 days away - set dates as follows
          this.updateInvoice(
            0,
            "date",
            closeDate ? closeDate.plus({ days: 30 }).toISODate() : false,
          );
          if (invoices.length > 1) {
            this.updateInvoice(
              1,
              "date",
              eventDate ? eventDate.minus({ days: 90 }).toISODate() : false,
            );
          }
          if (invoices.length > 2) {
            this.updateInvoice(
              2,
              "date",
              eventDate ? eventDate.minus({ days: 30 }).toISODate() : false,
            );
          }
        } else if (diff.toObject().days >= 90 && diff.toObject().days < 120) {
          this.updateInvoice(
            0,
            "date",
            closeDate ? closeDate.plus({ days: 30 }).toISODate() : false,
          );
          if (invoices.length > 1) {
            this.updateInvoice(
              1,
              "date",
              eventDate ? eventDate.minus({ days: 30 }).toISODate() : false,
            );
          }
        } else {
          // If less than 90 days away - just use the close date
          this.updateInvoice(
            0,
            "date",
            closeDate ? closeDate.toISODate() : false,
          );
        }
      }
    }

    invoices[targetInvoice].line_items = lineItems[0];
    if (this.state.deal.settings.mode === "deposit_recurring") {
      invoices[0].line_items = lineItems[0];
      invoices[1].line_items = lineItems[1];
    }

    this.setInvoiceState(invoices);

    if (
      this.state.deal.settings.mode === "recurring" ||
      this.state.deal.settings.mode === "Monthly"
    ) {
      this.mirrorInvoice(0);
    } else if (this.state.deal.settings.mode === "deposit_recurring") {
      this.mirrorInvoice(1);
    }

    this.setSuccess("Autofill completed");
    this.updateSetting("autofill", "complete");
  }

  async processQBOStartingToday() {
    // For each repeating invoice that starts today, create a non-repeating copy of the invoice dated today
    let invoices = this.state.invoices;
    let today = DateTime.now().toISODate();
    let newInvoices = [];
    invoices.forEach((invoice) => {
      if (invoice.repeating && invoice.scheduleStartDate === today) {
        let newInvoice = { ...invoice };
        newInvoice.repeating = false;
        newInvoice.scheduleStartDate = null;
        newInvoice.scheduleEndDate = null;
        newInvoice.schedulePeriod = null;
        newInvoice.scheduleUnit = null;
        newInvoice.date = today;
        newInvoice.key = this.generateRandomKey();
        newInvoice.id = 0;
        newInvoice.external_id = "";
        newInvoice.external_invoicenumber = "";
        newInvoice.status = "";
        newInvoice.sent = false;
        newInvoice.external_url = false;

        newInvoice = this.updateDueDate(newInvoice);
        newInvoices.push(newInvoice);
        // update the existing invoice and make it start from the next period

        let toAddToStartDate;
        if (invoice.scheduleUnit === "WEEKLY") {
          toAddToStartDate = { weeks: invoice.schedulePeriod };
        } else {
          toAddToStartDate = { months: invoice.schedulePeriod };
        }

        let newStartDate = DateTime.fromISO(invoice.scheduleStartDate)
          .plus(toAddToStartDate)
          .toISODate();

        if (newStartDate > invoice.scheduleEndDate) {
          // if the new start date is after the end date, then remove the invoice
          this.removeInvoice(invoices.indexOf(invoice));
        } else {
          this.updateInvoice(
            invoices.indexOf(invoice),
            "scheduleStartDate",
            newStartDate,
          );
          this.updateInvoice(
            invoices.indexOf(invoice),
            "hasNewStartDate",
            true,
          );
        }
      }
    });
    invoices = invoices.concat(newInvoices);
    this.setState({ invoices: invoices });
    this.setState({ confirmQBOStartingToday: false, loadingInline: false });
  }
  round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }

  generateRandomKey() {
    return Math.random().toString(36).substring(7);
  }

  toggleEditContact() {
    // This is up here so that you can trigger the edit modal from the send modal
    this.setState({ showEditContact: !this.state.showEditContact });
  }

  setError(title, inline = true, message = false, continueLink = false) {
    this.setState({
      loading: false,
      loadingInline: false,
      loadingSync: false,
      successAlert: false,
      errorAlert: inline,
      error: !inline,
      errorTitle: title,
      errorMessage: message,
      errorContinueLink: continueLink,
    });
  }

  setSuccess(message, inline = true, title = false) {
    this.setState({
      loading: false,
      loadingInline: false,
      loadingSync: false,
      successAlert: !inline,
      errorAlert: false,
      successInline: inline,
      successMessage: !inline ? message : false,
      successTitle: inline ? message : title,
    });
  }

  render() {
    const platformStyles = {
      "--platform":
        this.state?.connectionData?.connectionType === "xero"
          ? "#0078c8"
          : "#2ca01c",
      "--platformmid":
        this.state?.connectionData?.connectionType === "xero"
          ? "#13b5ea"
          : "#00892E",
      "--platformlight":
        this.state?.connectionData?.connectionType === "xero"
          ? "#cceffa"
          : "#ecf6ef",
    };

    const attachmentsCount = this.state.invoices.reduce(
      (count, invoice) => count + invoice.attachments.length,
      0,
    );

    const attachmentsSyncingCount = this.state.invoices.reduce(
      (count, invoice) => {
        return (
          count +
          invoice.attachments.filter((attachment) =>
            ["SYNCING"].includes(attachment.status),
          ).length
        );
      },
      0,
    );

    const attachmentsErrorCount = this.state.invoices.reduce(
      (count, invoice) => {
        return (
          count +
          invoice.attachments.filter(
            (attachment) => attachment.status === "UPLOAD-FAILED",
          ).length
        );
      },
      0,
    );

    return (
      <ErrorBoundary>
        <div style={platformStyles}>
          {this.state.loading ? (
            <LoadingState h1={this.state?.loadingTitle} />
          ) : null}
          <>
            {this.state.error ? (
              <ErrorState h1={this.state.errorTitle} />
            ) : (
              <>
                <InlineLoading
                  open={this.state.loadingInline}
                  h1={this.state.loadingTitle}
                  setClose={() => this.setState({ loadingInline: false })}
                />
                <InlineSuccess
                  open={this.state.successInline && !this.state.loadingInline}
                  title={this.state.successTitle}
                  setClose={() => this.setState({ successInline: false })}
                />
                <AlertError
                  open={this.state.errorAlert}
                  h1={this.state.errorTitle}
                  setClose={() => this.setState({ errorAlert: false })}
                  platformStyles={platformStyles}
                  message={this.state.errorMessage}
                  continueLink={this.state.errorContinueLink}
                />

                <Syncing
                  open={
                    this.state.saveStatus !== false ||
                    this.state.syncStatus !== false
                  }
                  saveStatus={this.state.saveStatus}
                  syncStatus={this.state.syncStatus}
                  attachmentsCount={attachmentsCount}
                  attachmentsSyncingCount={attachmentsSyncingCount}
                  attachmentsErrorCount={attachmentsErrorCount}
                  setClose={() =>
                    this.setState({
                      saveStatus: false,
                      syncStatus: false,
                      showUploadStatus: attachmentsSyncingCount > 0,
                    })
                  }
                  platformStyles={platformStyles}
                />

                <InlineLoading
                  open={
                    this.state.showUploadStatus && attachmentsSyncingCount > 0
                  }
                  h1={`Uploading Attachments... ${
                    attachmentsCount + 1 - attachmentsSyncingCount
                  }/${attachmentsCount}`}
                  setClose={() => this.setState({ showUploadStatus: false })}
                />

                <AlertConfirmation
                  open={!!this.state.confirmCancelInvoice}
                  title="Cancel Repeating Invoice?"
                  message="No further invoices will be created, but any existing invoices will stay unchanged."
                  confirmButtonText="Cancel"
                  cancelButtonText="Don't cancel"
                  confirmAction={this.cancelInvoice.bind(this)}
                  cancelAction={() => {
                    this.setState({ confirmCancelInvoice: null });
                  }}
                  platformStyles={platformStyles}
                />
                <AlertConfirmation
                  open={!!this.state.confirmReplaceInvoice}
                  title="Edit Repeating Invoice?"
                  message="When synced, this will cancel the existing template, and create a new one in Xero. Any existing invoices will stay unchanged."
                  confirmButtonText="Edit Invoice"
                  cancelButtonText="Don't edit"
                  confirmAction={this.replaceInvoice.bind(this)}
                  cancelAction={() => {
                    this.setState({ confirmReplaceInvoice: null });
                  }}
                  platformStyles={platformStyles}
                />
                <AlertConfirmation
                  open={!!this.state.confirmRefreshInvoice}
                  title="Refresh Invoice?"
                  message="This refreshes the invoice from your accounting platform, you may lose any draft changes you've made"
                  confirmButtonText="Refresh"
                  cancelButtonText="Don't refresh"
                  confirmAction={this.refreshInvoice.bind(this)}
                  cancelAction={() => {
                    this.setState({ confirmRefreshInvoice: null });
                  }}
                  platformStyles={platformStyles}
                />
                <AlertConfirmation
                  open={this.state.confirmQBOStartingToday}
                  title="Start Invoices Today?"
                  message="QuickBooks cannot start repeating invoices today. Invoice Stack will adjust today's invoice into a one-off invoice."
                  confirmButtonText="Adjust Invoices"
                  cancelButtonText="Cancel"
                  confirmAction={this.processQBOStartingToday.bind(this)}
                  cancelAction={() => {
                    this.setState({
                      confirmQBOStartingToday: false,
                      loadingInline: false,
                    });
                  }}
                  platformStyles={platformStyles}
                />
                {!this.state.preferences?.recurringBeta &&
                this.state.deal.settings.mode === "" &&
                this.state?.hubspotData &&
                !this.state.loading.getInvoices ? (
                  <ModeSelector
                    invoices={this.state.invoices}
                    deal={this.state.deal}
                    preferences={this.state.preferences}
                    updateSetting={this.updateSetting.bind(this)}
                    updateInvoiceCount={this.updateInvoiceCount.bind(this)}
                    setInvoiceState={this.setInvoiceState.bind(this)}
                    mirrorInvoice={this.mirrorInvoice.bind(this)}
                    hubspotData={this.state.hubspotData}
                    doAutoFill={this.doOldAutoFill.bind(this)}
                  />
                ) : (
                  ""
                )}
                {this.state?.connectionData && (
                  <>
                    <Sidebar
                      invoices={this.state.invoices}
                      deal={this.state.deal}
                      connectionData={this.state.connectionData}
                      hubspotData={this.state.hubspotData}
                      changed={this.state.changed}
                      preferences={this.state.preferences}
                      fetchedInvoices={this.state.fetchedInvoices}
                      updateSetting={this.updateSetting.bind(this)}
                      saveInvoices={this.saveInvoices.bind(this)}
                      syncInvoices={this.syncInvoices.bind(this)}
                      showEditContact={this.state.showEditContact}
                      toggleEditContact={this.toggleEditContact.bind(this)}
                      autoFill={this.autoFill.bind(this)}
                      setError={this.setError.bind(this)}
                      setSuccess={this.setSuccess.bind(this)}
                      globalData={this.globalData.bind(this)}
                      platformStyles={platformStyles}
                      getTaxFromTaxType={this.getTaxFromTaxType.bind(this)}
                    />
                    <InvoiceList
                      invoices={this.state.invoices}
                      invoiceErrors={this.state.invoiceErrors}
                      deal={this.state.deal}
                      hubspotData={this.state.hubspotData}
                      connectionData={this.state.connectionData}
                      preferences={this.state.preferences}
                      accounts={this.state.accounts}
                      taxes={this.state.taxes}
                      products={this.state.products}
                      tracking={this.state.tracking}
                      updateSetting={this.updateSetting.bind(this)}
                      updateInvoice={this.updateInvoice.bind(this)}
                      addAttachmentsToAllInvoices={this.addAttachmentsToAllInvoices.bind(
                        this,
                      )}
                      addInvoice={this.addInvoice.bind(this)}
                      addRecurringInvoice={this.addRecurringInvoice.bind(this)}
                      removeInvoice={this.removeInvoice.bind(this)}
                      cancelInvoice={this.confirmCancelInvoice.bind(this)}
                      replaceInvoice={this.confirmReplaceInvoice.bind(this)}
                      cloneInvoice={this.cloneInvoice.bind(this)}
                      splitInvoice={this.splitInvoice.bind(this)}
                      setInvoiceState={this.setInvoiceState.bind(this)}
                      platformStyles={platformStyles}
                      toggleEditContact={this.toggleEditContact.bind(this)}
                      setError={this.setError.bind(this)}
                      setSuccess={this.setSuccess.bind(this)}
                      refreshInvoice={this.confirmRefreshInvoice.bind(this)}
                      hubspotAttachments={this.state.hubspotAttachments}
                      hubspotAttachmentsLoading={
                        this.state.hubspotAttachmentsLoading
                      }
                      getHubspotAttachments={this.getHubspotAttachments}
                      allowAttachments={this.state?.allowAttachments}
                      uploadAttachment={this.uploadAttachment.bind(this)}
                      deleteAttachment={this.deleteAttachment.bind(this)}
                      createDeposit={this.createDeposit.bind(this)}
                    />
                  </>
                )}
              </>
            )}
          </>
        </div>
      </ErrorBoundary>
    );
  }
}

export default App;
