import React from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";

import asyncDebounce from "../utils/debounceAsync";

let api = process.env.REACT_APP_API_ROOT;

class ContactsField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    await this.onLoad();
  };

  componentDidUpdate = async () => {
    if (this.props?.update) {
      await this.onLoad();
    }
  };

  async onLoad() {
    if (this.props.contactName && !this.props.selectedContact) {
      let searchResults = await this.searchContacts(this.props.contactName);
      if (searchResults?.contacts && searchResults.contacts.length === 1) {
        await this.selectContact(searchResults.contacts[0]);
      }
      if (searchResults?.contacts && searchResults.contacts.length > 1) {
        let exactMatch = searchResults.contacts.find(
          (contact) => contact.label === this.props.contactName,
        );
        if (exactMatch) {
          await this.selectContact(exactMatch);
        }
      }
    } else if (this.props.selectedContact && !this.state.contactData) {
      // Updating with newly added contact
      let contactData = await this.getSingleContact(this.props.selectedContact);
      await this.selectContact(contactData);
    } else if (this.state.contactData?.value) {
      // Updating with newly edited contact
      let contactData = await this.getSingleContact(this.props.selectedContact);
      await this.selectContact(contactData); // check with Bridie about what this does
    }
    this.setState({ isLoading: false });
  }

  async selectContact(contactData) {
    await this.setState({ contactData: contactData });
    await this.props.updateContact(contactData?.value, contactData);
    if (contactData?.terms) {
      await this.props.updateTerms(contactData?.terms);
    }
    if (this.props?.updateEmail) {
      await this.props.updateEmail(contactData?.email);
    }
    this.setState({ isLoading: false });
  }

  async searchContacts(searchTerm) {
    let accessToken = new URLSearchParams(document.location.search).get(
      "access_token",
    );
    const results = await fetch(api + "/contacts/search/" + searchTerm, {
      headers: {
        "Content-Type": "application/json",
        accesstoken: accessToken,
      },
    }).then((res) => res.json());
    return results;
  }

  debouncedSearchContacts = asyncDebounce(this.searchContacts);

  async getSingleContact(contactId) {
    let accessToken = new URLSearchParams(document.location.search).get(
      "access_token",
    );
    const results = await fetch(api + "/contacts/getContact/" + contactId, {
      headers: {
        "Content-Type": "application/json",
        accesstoken: accessToken,
      },
    }).then((res) => res.json());
    if (!results?.contact) throw "Could not find contact";
    let contact = results.contact[0];
    contact.email = contact?.emails ? contact.emails[0] : "No Email Address";
    return results.contact[0];
  }

  loadOptions = (query) =>
    new Promise((resolve) => {
      this.debouncedSearchContacts(query).then((results) =>
        resolve(results.contacts),
      );
    });

  render() {
    const ContactControl = ({ children, ...props }) => {
      let selectedOption = props.getValue();
      return (
        <components.Control {...props}>
          <div className="flex flex-wrap items-center w-full">
            {selectedOption[0] ? (
              <div className="flex-1 min-w-0 p-2">
                <span className="block overflow-hidden text-xs text-ellipsis whitespace-nowrap">
                  {selectedOption[0]?.label}
                  {selectedOption[0]?.name}
                </span>
                <span className="block overflow-hidden text-xs text-grey text-ellipsis whitespace-nowrap">
                  {selectedOption[0]?.email
                    ? selectedOption[0]?.email
                    : "No Email Address"}
                </span>
              </div>
            ) : (
              <div className="flex-1 min-w-0 p-1">
                <span className="block overflow-hidden text-xs text-ellipsis whitespace-nowrap text-grey">
                  {children[0]}
                </span>
              </div>
            )}
            <div className="shrink-0">{children[1]}</div>
          </div>
        </components.Control>
      );
    };

    const ContactOption = (props) => {
      let selectedOption = props.data;
      return (
        <components.Option {...props}>
          <div className="text-xs">
            <div className={props.isSelected ? "text-white " : ""}>
              {selectedOption?.label}
            </div>
            <div className={props.isSelected ? "text-white " : "text-grey"}>
              {selectedOption?.email
                ? selectedOption.email
                : "No Email Address"}
            </div>
          </div>
        </components.Option>
      );
    };

    return (
      <div className="flex-1">
        <AsyncSelect
          className="text-sm w-60"
          loadingMessage={(e) =>
            e.inputValue.length < 3 ? "Type at least 3 characters" : "Searching"
          }
          noOptionsMessage={(e) =>
            e.inputValue.length < 3
              ? "Type at least 3 characters"
              : this.props.addContactLink
          }
          value={this.state.contactData}
          placeholder="Search"
          components={{ Option: ContactOption, Control: ContactControl }}
          isClearable
          openMenuOnFocus="true"
          isLoading={this.state.isLoading}
          loadOptions={this.loadOptions}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: this.props.platformStyles["--platformlight"],
              primary50: this.props.platformStyles["--platformlight"],
              primary: this.props.platformStyles["--platform"],
            },
            spacing: {
              ...theme.spacing,
              baseUnit: 3,
            },
          })}
          onChange={async (selectedOption) => {
            await this.selectContact(selectedOption);
          }}
        />
      </div>
    );
  }
}

export default ContactsField;
