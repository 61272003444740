/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { useLottie } from "lottie-react";
import paymentScheduleAnimation from "../../assets/payment-schedule-animation.json";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "./../utils/classNames";

import { ReceiptTaxIcon, XIcon } from "@heroicons/react/outline";
import { ChevronRightIcon } from "@heroicons/react/solid";

export default function PaymentSchedule({
  open,
  setClose,
  platformStyles,
  splits,
  splitAction,
  invoiceIndex,
  isTrial,
}) {
  const handleSplitAction = (invoiceIndex, split) => {
    splitAction(invoiceIndex, split);
    setClose(false);
  };
  const PaymentScheduleAnimation = () => {
    const { View } = useLottie(
      {
        animationData: paymentScheduleAnimation,
        loop: true,
        autoplay: true,
      },
      {
        height: 400,
        width: 400,
      },
    );
    return View;
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={setClose}
        style={platformStyles}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-white bg-opacity-90"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-flex flex-row max-h-[80vh] overflow-hidden text-left align-bottom transition-all transform bg-white border border-gray-100 shadow-xl shadow-neutral-100 rounded-2xl sm:max-w-4xl sm:my-8 sm:align-middle sm:w-full">
              <div className="absolute top-0 right-0 z-50 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none"
                  onClick={() => setClose(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex-1 px-10 py-8">
                <div className="flex flex-col h-full gap-5 pt-5">
                  <h1 className="flex items-center text-2xl font-semibold leading-6 text-gray-900">
                    Apply Payment Schedule
                    {isTrial && (
                      <span className="relative px-3 py-1 text-xs font-semibold tracking-wide uppercase rounded-full -right-4 bg-palePrimary text-secondary">
                        Pro
                      </span>
                    )}
                  </h1>
                  <div>
                    <p className="text-sm leading-tight ">
                      Split this invoice into multiple invoices based on your
                      preset templates. Line items will be the same on all
                      invoices. <br />
                      <a
                        href={process.env.REACT_APP_SCHEDULES_LINK}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center text-platform"
                      >
                        Learn more{" "}
                        <ChevronRightIcon className="inline-block w-4 h-4 text-platform" />
                      </a>
                    </p>
                  </div>
                  <div className="flex flex-col h-full overflow-x-scroll text-left divide-y divide-gray-200">
                    {splits && splits.length > 0 ? (
                      <>
                        {splits.map((split) => (
                          <button
                            onClick={() =>
                              handleSplitAction(invoiceIndex, split)
                            }
                            key={"split_" + split.id}
                            className={classNames(
                              "group flex-1 w-full p-2 flex text-left hover:bg-gray-50",
                            )}
                          >
                            <div className="flex flex-col flex-1 items-left">
                              {split.label}
                              <div className="flex flex-row gap-1 text-xs">
                                {split.percentages
                                  .split("%")
                                  .filter((i) => parseFloat(i))
                                  .map((value) => (
                                    <div key={value} className="text-grey">
                                      {value}%
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="flex items-center justify-end w-12">
                              <ChevronRightIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
                            </div>
                          </button>
                        ))}
                      </>
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full h-full gap-2">
                        <ReceiptTaxIcon className="w-12 h-12 text-gray-300 stroke-1" />
                        <div className="text-sm text-gray-400">
                          No payment schedules set up
                        </div>
                        <a
                          href={process.env.REACT_APP_LINK_PREFERENCES}
                          target="_blank"
                          rel="noreferrer"
                          className="flex flex-row items-center text-xs text-platform"
                        >
                          Get started in your dashboard
                          <ChevronRightIcon className="inline-block w-4 h-4 text-platform" />
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="w-full text-center"></div>
                </div>
              </div>
              <div className="flex items-center justify-center flex-1 blend-background">
                <PaymentScheduleAnimation />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
