import React from "react";
import SwitchGroup from "./fields/SwitchGroup";
import NumberPicker from "./fields/NumberPicker";
import { DateTime } from "luxon";

class ModeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      period: "",
      count: 1,
      autofill: "disabled",
      deposit: "disabled",
    };
  }

  componentDidMount = async () => {
    console.log("Mode Selector");
    if (
      (!this.props.deal.settings?.modeSettings &&
        this.props.hubspotData.lineItemData.length > 0) ||
      this.props.preferences?.dummyLineItem
    ) {
      this.setState({ autofill: true });
    }

    if (this.props.invoices.length > 0) {
      this.setState({ count: this.props.invoices.length });
    } else if (this.props.hubspotData.lineItemSettings?.count > 1) {
      this.setState({ count: this.props.hubspotData.lineItemSettings.count });
    }

    if (this.props.deal.settings?.modeSettings) {
      if (Object.hasOwn(this.props.deal.settings.modeSettings[0], "period")) {
        this.setState({
          period: this.props.deal.settings.modeSettings[0].period,
        });
      }
      if (Object.hasOwn(this.props.deal.settings.modeSettings[0], "deposit")) {
        this.setState({
          deposit: this.props.deal.settings.modeSettings[0].deposit,
        });
      }
    }

    if (
      !this.props.deal.settings?.modeSettings &&
      this.props.hubspotData.lineItemSettings?.period
    ) {
      this.setState({
        period: this.props.hubspotData.lineItemSettings.period,
        deposit: this.props.hubspotData.lineItemSettings.deposit,
        autofill: true,
      });
      // Add an extra invoice if we have a line item which is a one-off
      if (
        this.props.hubspotData.lineItemSettings.deposit &&
        this.props.hubspotData.lineItemSettings.period !== ""
      ) {
        this.setState({
          count: parseInt(this.props.hubspotData.lineItemSettings.count) + 1,
        });
      }
    }

    if (this.props.deal.settings.autofill === "complete") {
      // Do not select autofill if it's already been completed
      this.setState({ autofill: false });
    }

    // CUSTOM - for vuka
    if (this.props.preferences?.custom === "vuka") {
      // if certain teams - set the number of invoices to 3
      let vukaDealTypes =
        this.props.hubspotData.properties.product_type.split(";");
      if (vukaDealTypes.includes("Live")) {
        let closeDate = DateTime.fromISO(
          this.props.hubspotData.properties.closedate,
        );
        let eventDate = DateTime.fromISO(
          this.props.hubspotData.properties.event_campaign_start_date,
        );
        let diff = eventDate.diff(closeDate, "days");
        console.log(diff.toObject().days);
        if (diff.toObject().days >= 120) {
          this.setState({ count: 3, autofill: true });
        } else if (diff.toObject().days >= 90 && diff.toObject().days < 120) {
          this.setState({ count: 2, autofill: true });
        }
      }
    }
    this.start.focus();
  };
  handleKeypress(e) {
    //Trigger start when you press enter
    if (e.key === "Enter") {
      this.startMode();
    }
  }
  startMode() {
    if (this.state.period !== "") {
      // Save settings
      if (this.state.deposit === true) {
        this.props.updateSetting("mode", "deposit_recurring");
        this.props.updateSetting("modeSettings", [
          {
            period: this.state.period,
            count: this.state.count,
            deposit: true,
          },
        ]);
      } else {
        this.props.updateSetting("mode", "recurring");
        this.props.updateSetting("modeSettings", [
          {
            period: this.state.period,
            count: this.state.count,
            deposit: false,
          },
        ]);
      }
    } else {
      this.props.updateSetting("mode", "Custom");
    }

    this.props.updateInvoiceCount(this.state.count);

    if (this.state.autofill === true) {
      this.props.doAutoFill();
    }
  }
  setCount(val) {
    if (val === "") {
      this.setState({ count: val });
    } else if (val > 0) {
      this.setState({ count: parseInt(val) });
    }
  }
  setDeposit(val) {
    if (val) {
      this.setCount(this.state.count + 1);
    } else {
      this.setCount(this.state.count - 1);
    }
    this.setState({ deposit: val });
  }
  render() {
    return (
      <div className="fixed top-0 z-40 flex flex-col w-screen h-screen p-10 overflow-y-scroll bg-white">
        <div className="flex flex-col flex-1">
          <div className="flex flex-row items-center flex-1 h-full p-5 divide-x invoice-count">
            <div className="flex flex-1 h-full place-items-center">
              <div className="mx-auto">
                <h2 className="text-xl text-center">How many invoices?</h2>
                <NumberPicker
                  value={this.state.count}
                  updateHandler={this.setCount.bind(this)}
                  handleKeypress={this.handleKeypress.bind(this)}
                  className="flex items-center justify-center flex-1"
                />
                {this.props.hubspot?.plan === "starter" ? (
                  <div className="mt-6">
                    <SwitchGroup
                      label="AutoFill"
                      description="Use line items from your HubSpot deal"
                      enabled={this.state.autofill}
                      disabled={this.state.autofill === "disabled"}
                      onSwitch={(e) => {
                        this.setState({
                          autofill: e,
                        });
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {this.props.hubspot?.plan !== "starter" ? (
              <div className="flex flex-col justify-center flex-1 h-full">
                <div className="px-10 mx-auto">
                  <SwitchGroup
                    label="AutoFill"
                    description="Use line items from your HubSpot deal"
                    enabled={this.state.autofill}
                    disabled={this.state.autofill === "disabled"}
                    onSwitch={(e) => {
                      this.setState({
                        autofill: e,
                      });
                    }}
                  />

                  <div className="flex flex-col gap-4 mt-10 field">
                    <label>
                      <div className="flex font-medium text-gray-900 align-middle text-md">
                        Recurring
                        {this.props.hubspot?.plan === "trial" ? (
                          <span className="inline-flex px-3 py-1 ml-2 text-xs font-semibold tracking-wide uppercase rounded-full bg-palePrimary text-secondary">
                            Pro
                          </span>
                        ) : null}
                      </div>
                      <div className="text-sm text-grey">
                        Create identical repeating invoices
                      </div>
                    </label>
                    <span className="flex flex-col gap-2 pl-4 input">
                      <span className="flex items-center gap-4 radio">
                        <input
                          type="radio"
                          name="recurring"
                          id="recurring-not"
                          checked={this.state.period === ""}
                          onChange={() => {
                            this.setState({
                              mode: "custom",
                              period: "",
                              deposit: "disabled",
                            });
                          }}
                        />
                        <label htmlFor="recurring-not" className="radio-label">
                          Not recurring
                        </label>
                      </span>
                      <span className="flex items-center gap-4 radio">
                        <input
                          type="radio"
                          name="recurring"
                          id="recurring-m"
                          checked={this.state.period === "month"}
                          onChange={() => {
                            this.setState({
                              mode: "recurring",
                              period: "month",
                              deposit: false,
                            });
                          }}
                        />
                        <label htmlFor="recurring-m" className="radio-label">
                          Monthly
                        </label>
                      </span>
                      <span className="flex items-center gap-4 radio">
                        <input
                          type="radio"
                          name="recurring"
                          id="recurring-q"
                          checked={this.state.period === "quarter"}
                          onChange={() => {
                            this.setState({
                              mode: "recurring",
                              period: "quarter",
                              deposit: false,
                            });
                          }}
                        />
                        <label htmlFor="recurring-q" className="radio-label">
                          Quarterly
                        </label>
                      </span>
                      <span className="flex items-center gap-4 radio">
                        <input
                          type="radio"
                          name="recurring"
                          id="recurring-y"
                          checked={this.state.period === "year"}
                          onChange={() => {
                            this.setState({
                              mode: "recurring",
                              period: "year",
                              deposit: false,
                            });
                          }}
                        />
                        <label htmlFor="recurring-y" className="radio-label">
                          Annually
                        </label>
                      </span>
                      <span className="flex items-center gap-4 radio">
                        <input
                          type="checkbox"
                          name="deposit"
                          id="deposit"
                          className="fancy-checkbox"
                          checked={this.state.deposit}
                          disabled={this.state.deposit === "disabled"}
                          onChange={(e) => {
                            this.setDeposit(e.target.checked);
                          }}
                        />
                        <label htmlFor="deposit" className="checkbox-label">
                          Deposit
                        </label>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col self-center w-screen max-w-sm pt-5 text-center">
            <button
              type="submit"
              onClick={() => {
                this.startMode();
              }}
              ref={(x) => (this.start = x)}
              className="px-6 py-3 text-white rounded-md bg-platform focus:outline-none"
            >
              Start Now
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ModeSelector;
