/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Switch } from "@headlessui/react";
import classNames from "./../utils/classNames";

export default function SwitchGroup({
  label,
  description,
  onSwitch,
  disabled = false,
  enabled = false,
}) {
  return (
    <Switch.Group as="div" className="flex items-center justify-between gap-5">
      <span className="flex flex-col flex-grow">
        <Switch.Label as="span" className="font-medium text-md" passive>
          {label}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-grey">
          {description}
        </Switch.Description>
      </span>
      <Switch
        checked={enabled}
        onChange={onSwitch}
        className={classNames(
          enabled ? "bg-platform" : "bg-gray-200",
          disabled ? "!bg-gray-300 pointer-events-none" : "",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-platform",
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
          )}
        />
      </Switch>
    </Switch.Group>
  );
}
