import React from "react";
import Select, { components } from "react-select";

import AddContact from "./modals/AddContact";
import EditContact from "./modals/EditContact";
import { UserAddIcon } from "@heroicons/react/outline";
import ContactsField from "./fields/ContactsField";
class XeroSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      taxes: [],
      currencies: [],
      accounts: [],
      themes: false,
      tracking: {},
      loading: true,
      showAddContact: false,
      error: false,
    };
  }
  componentDidMount = async () => {
    const settings = this.props.connectionSettings;

    if (
      this.props.settings.currency === "" &&
      settings.currencies.includes(
        this.props.hubspotData.properties.deal_currency_code,
      )
    ) {
      // If deal has a currency and it's in the list, select it
      this.props.updateSetting(
        "currency",
        this.props.hubspotData.properties.deal_currency_code,
        true,
      );
      console.log("dealCurrency");
    } else if (
      this.props.settings.currency === "" &&
      settings.connectionPreferences.defaultCurrency
    ) {
      // If default currency set by QBO then use it
      this.props.updateSetting(
        "currency",
        settings.connectionPreferences.defaultCurrency,
        true,
      );
      console.log("connectionPreferences");
    } else if (
      this.props.settings.currency === "" &&
      this.props.preferences?.defaultCurrency
    ) {
      // If default currency set in settings then use it
      console.log("defaultCurrency");
      this.props.updateSetting(
        "currency",
        this.props.preferences.defaultCurrency,
        true,
      );
    } else if (this.props.settings.currency === "") {
      // Otherwise use the first currency on the list
      this.props.updateSetting("currency", settings.currencies[0], true);
      console.log("onlyCurrency");
    }
    if (settings?.terms) {
      this.props.updateSetting("terms", settings.terms, false);
    }
    this.props.globalData("accounts", settings.accounts);
    this.props.globalData("taxes", settings.taxes);
    this.props.globalData("tracking", settings.tracking);
    this.props.globalData("products", settings?.products);
    this.props.globalData("preferences", settings.connectionPreferences);

    await this.setState({
      taxes: settings.taxes,
      currencies: settings.currencies,
      accounts: settings.accounts,
      tracking: settings.tracking,
      themes: settings.themes,
      loading: false,
    });

    if (
      this.props.preferences?.defaultAccount &&
      !this.props.settings?.account
    ) {
      this.updateAccount(
        this.props.hubspotData?.defaultAccount ||
          this.props.preferences.defaultAccount,
      );
    }
    if (this.props.preferences?.defaultTax && !this.props.settings?.taxType) {
      this.updateTax(this.props.preferences.defaultTax);
    }
    if (
      this.props.preferences?.brandingThemes &&
      settings.themes &&
      !this.props.settings?.theme
    ) {
      // If there a multiple themes in settings.themes, loop through settings.themes and see if any of the names contain the (case-insensitive) value of this.props.settings.currency (e.g. "NZD" or "AUD"), otherwise just use the first theme in the list
      let theme = settings.themes[0];
      if (settings.themes.length > 1) {
        for (const t of settings.themes) {
          if (
            t.name
              .toLowerCase()
              .includes(this.props.settings.currency.toLowerCase())
          ) {
            theme = t;
          }
        }
      }
      this.updateTheme(theme.id);
    }

    // AutoFill tracking categories from deal properties
    if (Object.hasOwn(this.props?.preferences, "tracking_autofill")) {
      for (const trackingCategory of this.props.preferences.tracking_autofill) {
        if (trackingCategory.value === "hubspot_owner_id") {
          trackingCategory.value = "hubspot_owner";
        }
        if (
          Object.hasOwn(
            this.props.hubspotData?.properties,
            trackingCategory.value,
          )
        ) {
          this.updateTracking(
            trackingCategory.name,
            this.props.hubspotData.properties[trackingCategory.value],
          );
        }
      }
    }
  };

  addNewContact(contact) {
    this.props.updateSetting("contact", contact.value, false);
    this.setState({ showAddContact: false });
    this.setState({ updateContactsField: true });
    this.setState({ updateContactsField: false });
  }
  cancelNewContact() {
    this.setState({ addcontact: false });
  }
  replaceContactData() {
    // replaces contact data when updated via the edit contact modal
    this.props.toggleEditContact();
    this.setState({ updateContactsField: true });
    this.setState({ updateContactsField: false });
  }
  updateContact(value, contactData = false) {
    if (contactData?.defaultTax) {
      this.props.updateSetting("contactHasCustomTax", contactData.defaultTax);
    }
    this.props.updateSetting("contact", value, false);
  }
  updateTerms(value) {
    this.props.updateSetting("terms", value, false);
  }
  updateTheme(value) {
    this.props.updateSetting("theme", value, false);
  }
  updateAccount(accountCode) {
    if (accountCode !== "NONE") {
      //Pick relevant tax code when account selected
      const account = this.state.accounts.find((x) => x.code === accountCode);
      if (!account) throw "account not found";
      this.props.updateSetting("account", account.code, false, true);

      const tax = this.state.taxes.find((x) => x.type === account.taxType);
      if (typeof tax === "undefined") {
        this.props.updateSetting("tax", 0, false, true);
      } else {
        this.props.updateSetting("taxType", tax.type, false, true);
        this.props.updateSetting("tax", tax.rate, false, true);
      }
    } else {
      this.props.updateSetting("account", "NONE", false, true);
    }
  }
  updateTax(taxType) {
    const tax = this.props.getTaxFromTaxType(taxType);
    this.props.updateSetting("taxType", tax.type, false, true);
    this.props.updateSetting("tax", tax.rate, false, true);
  }
  updateTracking(categoryName, optionName) {
    let trackingData = this.props.settings.tracking
      ? this.props.settings.tracking
      : {};
    if (Object.hasOwn(trackingData, categoryName)) {
      delete trackingData.categoryName;
    }
    trackingData[categoryName] = optionName;
    this.props.updateSetting("tracking", trackingData, true);
  }
  render() {
    const addContactLink = (
      <span className="text-xs">
        No contact found in Xero
        <br />
        {Object.hasOwn(this.props?.preferences, "add_contacts") &&
          this.props.preferences.add_contacts === true && (
            <button
              className="underline text-platform"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showAddContact: true });
              }}
            >
              Add New
            </button>
          )}
      </span>
    );

    const CurrencyControl = ({ children, ...props }) => {
      let selectedOption = props.getValue();
      let classes =
        "currency-flag rounded-full h-4 w-4 bg-center ml-2 mr-1 currency-flag-" +
        selectedOption[0]?.value.toLowerCase();
      return (
        <components.Control {...props}>
          <div className={classes}></div>
          {children}
        </components.Control>
      );
    };
    const CurrencyOption = (props) => {
      let selectedOption = props.data.value
        ? props.data.value.toLowerCase()
        : null;
      let classes =
        "currency-flag rounded-full h-4 w-4 bg-center mr-2 currency-flag-" +
        selectedOption;
      return (
        <components.Option {...props}>
          <div
            className={
              (props.isSelected ? "text-white " : "") + "flex items-center"
            }
          >
            <div className={classes}></div>
            {props.children}
          </div>
        </components.Option>
      );
    };

    const currencyList = this.state.currencies.map((x) => {
      return { value: x, label: x };
    });
    let accountList = this.state.accounts.map((x) => {
      return { value: x.code, label: x.name };
    });
    accountList = [...[{ value: "NONE", label: "None" }], ...accountList];
    let taxList = this.state.taxes.map((x) => {
      return { value: x.type, label: x.name };
    });
    taxList = [...[{ value: false, label: "Not Set" }], ...taxList];
    let trackingList = {};
    if (this.state.tracking.length) {
      for (const trackingCategory of this.state.tracking) {
        let data = trackingCategory.options.map((option) => {
          return { value: option.Name, label: option.Name };
        });
        data = [...[{ value: "NONE", label: "None" }], ...data];
        trackingList[trackingCategory.name] = data;
      }
    }
    const themesList = this.state.themes
      ? this.state.themes.map((x) => {
          return { value: x.id, label: x.name };
        })
      : false;

    function selectTheme(theme) {
      return {
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#cceffa",
          primary50: "#cceffa",
          primary: "#0078c8",
        },
        spacing: {
          ...theme.spacing,
          baseUnit: 3,
        },
      };
    }

    return (
      <div className="flex gap-2 xl:gap-4">
        <AddContact
          open={this.state.showAddContact}
          setClose={() => this.setState({ showAddContact: false })}
          hubspotData={this.props.hubspotData}
          addNewContact={this.addNewContact.bind(this)}
          cancelNewContact={this.cancelNewContact.bind(this)}
          setError={this.props.setError}
          setSuccess={this.props.setSuccess}
          platformStyles={this.props.platformStyles}
          connectionData={this.props.connectionData}
          b2c={this.props.preferences?.b2c}
          taxes={taxList}
          selectTheme={selectTheme}
        />

        <EditContact
          open={this.props.showEditContact}
          setClose={this.props.toggleEditContact}
          contactId={this.props.settings.contact}
          replaceContactData={this.replaceContactData.bind(this)}
          setError={this.props.setError}
          setSuccess={this.props.setSuccess}
          connectionData={this.props.connectionData}
          platformStyles={this.props.platformStyles}
          taxes={taxList}
          selectTheme={selectTheme}
        />

        <div className="flex flex-col items-start flex-1 gap-1">
          <label>
            <span className="flex flex-row text-xs text-grey">
              <div className="flex-1">Contact</div>
            </span>
            <ContactsField
              updateContact={this.updateContact.bind(this)}
              selectedContact={this.props.settings.contact}
              contactName={
                this.props.preferences?.b2c ||
                this.props.preferences?.searchByEmail
                  ? this.props.hubspotData.contactData?.email
                  : this.props.hubspotData?.companyData?.name
              }
              addContactLink={addContactLink}
              updateTerms={this.updateTerms.bind(this)}
              update={this.state?.updateContactsField}
              platformStyles={this.props.platformStyles}
            />
          </label>
          {this.props.settings.contact &&
          this.props.connectionData?.plan !== "starter" ? (
            <button
              className="flex justify-center text-sm underline text-platform"
              onClick={() => this.props.toggleEditContact()}
            >
              <UserAddIcon className="inline w-4 mr-1" />
              Edit Contact
            </button>
          ) : (
            <button
              className="flex justify-center text-sm underline text-platform"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showAddContact: true });
              }}
            >
              <UserAddIcon className="inline w-4 mr-1" />
              Add New Contact
            </button>
          )}
        </div>
        <div className="flex flex-wrap items-start w-full gap-2 xl:gap-4">
          {Object.hasOwn(this.state, "currencies") &&
          this.state.currencies.length ? (
            <label className="flex-shrink">
              <span className="flex flex-row text-xs text-grey">Currency</span>
              <div className="flex">
                <Select
                  className="text-xs w-28 xl:w-36"
                  classNamePrefix=""
                  defaultValue={currencyList.find(
                    (x) => x.value === this.props.settings.currency,
                  )}
                  components={{
                    Option: CurrencyOption,
                    Control: CurrencyControl,
                  }}
                  options={currencyList}
                  placeholder="Currency"
                  theme={selectTheme}
                  onChange={(selectedOption) => {
                    this.props.updateSetting(
                      "currency",
                      selectedOption.value,
                      true,
                    );
                  }}
                />
              </div>
            </label>
          ) : (
            ""
          )}

          {this.state?.accounts && this.state.accounts.length ? (
            <label className="flex-shrink">
              <span className="flex flex-row text-xs text-gray-700">
                Account
              </span>
              <div
                className={
                  this.props.allEditableTaxesAreCustom
                    ? "relative cursor-help group"
                    : ""
                }
              >
                <Select
                  className="text-xs w-28 xl:w-36"
                  classNamePrefix=""
                  value={accountList.find(
                    (x) => x.value === this.props.settings.account,
                  )}
                  options={accountList}
                  isDisabled={this.props.allEditableTaxesAreCustom}
                  placeholder="Account"
                  theme={selectTheme}
                  onChange={(selectedOption) => {
                    this.updateAccount(selectedOption.value);
                  }}
                />
                {this.props.allEditableTaxesAreCustom && (
                  <span className="absolute overflow-visible opacity-0 transition-all px-3 py-1 text-xs text-right bg-black text-white rounded-md shadow-sm bottom-100% left-1 mt-2 whitespace-nowrap group-hover:opacity-100">
                    Accounts are currently set per line item
                    <span className="absolute top-0 left-2 -mt-1.5 w-0 h-0 border-l-[.5rem] border-l-transparent border-b-[.75rem] border-b-black border-r-[.5rem] border-r-transparent"></span>
                  </span>
                )}
              </div>
            </label>
          ) : (
            ""
          )}

          {Object.hasOwn(this.state, "taxes") && this.state.taxes.length ? (
            <label className="flex-shrink">
              <span className="flex flex-row text-xs text-gray-700">Tax</span>
              <div
                className={
                  this.props.allEditableTaxesAreCustom
                    ? "relative cursor-help group"
                    : ""
                }
              >
                <Select
                  className="text-xs w-28 xl:w-36"
                  classNamePrefix=""
                  value={taxList.find(
                    (x) => x.value === this.props.settings.taxType,
                  )}
                  options={taxList}
                  isDisabled={this.props.allEditableTaxesAreCustom}
                  placeholder="Tax"
                  theme={selectTheme}
                  onChange={(selectedOption) => {
                    this.updateTax(selectedOption.value);
                  }}
                />
                {this.props.allEditableTaxesAreCustom && (
                  <span className="absolute overflow-visible opacity-0 transition-all px-3 py-1 text-xs text-right bg-black text-white rounded-md shadow-sm bottom-100% left-1 mt-2 whitespace-nowrap group-hover:opacity-100">
                    Taxes are currently set per line item
                    <span className="absolute top-0 left-2 -mt-1.5 w-0 h-0 border-l-[.5rem] border-l-transparent border-b-[.75rem] border-b-black border-r-[.5rem] border-r-transparent"></span>
                  </span>
                )}
              </div>
            </label>
          ) : (
            ""
          )}

          {this.props.preferences?.tracking &&
          this.props.connectionData?.plan !== "starter" &&
          this.state.tracking &&
          this.state.tracking.length > 0 ? (
            <>
              {this.state.tracking.map((trackingCategory, i) => (
                <label className="flex-shrink" key={i}>
                  <span className="flex flex-row text-xs text-gray-700">
                    {trackingCategory.name}
                  </span>
                  <div
                    className={
                      this.props.allEditableTaxesAreCustom
                        ? "relative cursor-help group"
                        : ""
                    }
                  >
                    <Select
                      className="text-xs w-28 xl:w-36"
                      classNamePrefix=""
                      options={trackingList[trackingCategory.name]}
                      value={trackingList[trackingCategory.name].find(
                        (x) =>
                          x.value ===
                          this.props.settings?.tracking?.[
                            trackingCategory.name
                          ],
                      )}
                      placeholder={trackingCategory.Name}
                      isDisabled={
                        this.props.allEditableTaxesAreCustom &&
                        this.props.preferences.trackingByLine
                      }
                      theme={selectTheme}
                      onChange={(selectedOption) => {
                        this.updateTracking(
                          trackingCategory.name,
                          selectedOption.value,
                        );
                      }}
                    />

                    {this.props.allEditableTaxesAreCustom &&
                      this.props.preferences.trackingByLine && (
                        <span className="absolute overflow-visible opacity-0 transition-all px-3 py-1 text-xs text-right bg-black text-white rounded-md shadow-sm bottom-100% left-1 mt-2 whitespace-nowrap group-hover:opacity-100">
                          Tracking Categories are currently set per line item
                          <span className="absolute top-0 left-2 -mt-1.5 w-0 h-0 border-l-[.5rem] border-l-transparent border-b-[.75rem] border-b-black border-r-[.5rem] border-r-transparent"></span>
                        </span>
                      )}
                  </div>
                </label>
              ))}
            </>
          ) : (
            ""
          )}

          {this.props.preferences?.brandingThemes && this.state?.themes ? (
            <label className="flex-shrink">
              <span className="flex flex-row text-xs text-gray-700">
                Themes
              </span>
              <div className="flex">
                <Select
                  className="text-xs w-28 xl:w-36"
                  classNamePrefix=""
                  value={themesList.find(
                    (x) => x.value === this.props.settings.theme,
                  )}
                  options={themesList}
                  placeholder="Themes"
                  theme={selectTheme}
                  onChange={(selectedOption) => {
                    this.updateTheme(selectedOption.value);
                  }}
                />
              </div>
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default XeroSettings;
