import React from "react";

class NumberDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  toCurrency(number) {
    try {
      const formatter = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: this.props.currency,
      });
      return formatter.format(number);
    } catch {
      return "";
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.toCurrency(this.props.value)}
      </div>
    );
  }
}

export default NumberDisplay;
