/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import classNames from "./../utils/classNames";

export default function InvoiceActions({
  connectionType,
  cloneAction,
  deleteAction,
  deleteEnabled,
  viewLink,
  pdfLink,
  copyAction,
  externalUrl,
  changeAction,
  changeEnabled,
  cancelEnabled,
  cancelAction,
  replaceEnabled,
  replaceAction,
  refreshEnabled,
  refreshAction,
  error,
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          onClick={(e) => e.stopPropagation()}
          className={classNames(
            "flex items-center p-0.5 transition-all rounded-full focus:outline-none hover:bg-white",
            error ? "text-red-600" : "text-platform",
            error ? "bg-red-100" : "bg-platformlight",
          )}
        >
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 w-56 mt-1 origin-top-right bg-white rounded-md shadow-lg outline outline-gray-50">
          <div className="py-1">
            {viewLink && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={viewLink}
                    target="_blank"
                    className={classNames(
                      active
                        ? "bg-platformlight text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm  w-full text-left",
                    )}
                    rel="noreferrer"
                  >
                    View in {connectionType === "xero" ? "Xero" : "QuickBooks"}
                  </a>
                )}
              </Menu.Item>
            )}
            {changeEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={changeAction}
                    className={classNames(
                      active
                        ? "bg-platformlight text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left",
                    )}
                  >
                    Edit Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {replaceEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={replaceAction}
                    className={classNames(
                      active
                        ? "bg-platformlight text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left",
                    )}
                  >
                    Edit Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {refreshEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={refreshAction}
                    className={classNames(
                      active
                        ? "bg-platformlight text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left",
                    )}
                  >
                    Refresh Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {externalUrl && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={copyAction}
                    className={classNames(
                      active
                        ? "bg-platformlight text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left",
                    )}
                  >
                    Copy Invoice Link
                  </button>
                )}
              </Menu.Item>
            )}
            {pdfLink && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={pdfLink}
                    target="_blank"
                    className={classNames(
                      active
                        ? "bg-platformlight text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left",
                    )}
                    rel="noreferrer"
                  >
                    Get PDF Invoice
                  </a>
                )}
              </Menu.Item>
            )}
            {externalUrl && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={externalUrl}
                    target="_blank"
                    className={classNames(
                      active
                        ? "bg-platformlight text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm  w-full text-left",
                    )}
                    rel="noreferrer"
                  >
                    Preview Invoice
                  </a>
                )}
              </Menu.Item>
            )}

            {changeEnabled || (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={cloneAction}
                    className={classNames(
                      active
                        ? "bg-platformlight text-gray-900"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm  w-full text-left",
                    )}
                  >
                    Duplicate Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {deleteEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={deleteAction}
                    className={classNames(
                      active ? "bg-platformlight text-red-600" : "text-red-600",
                      "block px-4 py-2 text-sm  w-full text-left",
                    )}
                  >
                    Delete Invoice
                  </button>
                )}
              </Menu.Item>
            )}
            {cancelEnabled && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={cancelAction}
                    className={classNames(
                      active ? "bg-platformlight text-red-600" : "text-red-600",
                      "block px-4 py-2 text-sm  w-full text-left",
                    )}
                  >
                    Cancel Invoice
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
